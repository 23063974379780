@import "./media.scss";

$bp1024_max_width: 900px;
$bp1200_max_width: 1012px;

.sub_container {
  &.event {
    > section {
      .event_list {
        @include bp1200 {
          width: $bp1200_max_width;
        }
        @include bp1024 {
          width: $bp1024_max_width;
        }
        @media (min-width: 800px) and (max-width: 900px) {
          width: 756px;
        }
        @media (min-width: 768px) and (max-width: 799px) {
          width: 713px;
        }
        @include bp768 {
          width: 100%;
        }
        @include bp425 {
          width: 100%;
        }
        .list_detizen {
          @include bp768 {
            padding: 0em 1em;
          }
          @include bp425 {
            padding: 0em 0.5em;
          }
        }
        .list_host {
          @include bp768 {
            padding: 0em 1em;
          }
          @include bp425 {
            padding: 0em 0.5em;
          }

          // 이벤트 일반 데이터 리스트
          ul.thumbList {
            @include bp425 {
              flex-direction: column;
              > li {
                width: 100%;
              }
            }
            > li {
              p.desc {
                font-size: 0.6em;
                max-height: 70px;
                overflow-y: hidden;
                white-space: normal;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }

      &.section.list {
        @include bp425 {
          padding-top: 2em;
        }
        > .event_list {
          @include bp425 {
            padding: 0em 0.5em;
          }

          .board_view.event {
            > ul {
              > li {
                &.th {
                  &.view {
                    .info {
                      display: flex;
                      @include bp425 {
                        flex-direction: column;
                      }
                      > p {
                        @include bp425 {
                          border: unset;
                        }
                        &.dateTitle {
                          @include bp425 {
                            font-weight: bold;
                          }
                        }
                        &.board_status {
                          @include bp425 {
                            margin: 0;
                            text-align: right;
                          }
                        }
                      }
                    }
                  }
                }
                &.td {
                  &.eventApply {
                    @include bp425 {
                      padding: 2em 0 0 0;
                    }
                    .applyList {
                      @include bp425 {
                        flex-direction: column;
                        align-items: flex-start;
                      }
                      > span {
                        &.input_title {
                          @include bp425 {
                            text-align: left;
                            width: 100% !important;
                          }
                        }
                      }
                      > div.input_area {
                        @include bp425 {
                          text-align: left;
                          width: 100% !important;
                        }

                        > label {
                          @include bp425 {
                            .apply_text {
                              margin-top: 0.5em;

                              width: 100%;
                            }
                          }
                        }
                      }
                    }

                    .btn_area {
                      @include bp425 {
                        padding-bottom: 1em;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // 이벤트 상세 start
    h2.index_title {
      @include bp425 {
        margin: 1em auto 0.5em;
      }
    }
  }
}
