@import "./media.scss";
@import "./common.module.scss";

$bg-white: #fff;
$border-grey: #dcdcdc;
$li-white: #f5f5f5;
$bold-color: #263050;
$em-sm: 0.25em;
$em-md: 0.5em;
$em-lg: 1em;
$em-xl: 1.25em;

@mixin w-h-100 {
  width: 100%;
  height: 100%;
}
@mixin wCalc($full, $margin, $divide) {
  width: calc((#{$full} - #{$margin}) / #{$divide});
}

.wrap.login,
.wrap.join {
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: auto;
  transform: translate(-50%, -50%);
}
.wrap.login {
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
  width: 900px;

  @media (min-width: 769px) and (max-width: 910px) {
    width: 100%;
    padding: 1em;
  }
  @include bp768 {
    width: 100%;
    padding: 1em;
    &.singin {
      position: relative;
      flex-direction: column;
      top: unset;
      left: unset;
      min-height: auto;
      transform: unset;
    }
    &.pass {
      position: relative;
      height: 100vh;
      top: unset;
      left: unset;
      min-height: auto;
      transform: unset;
    }
  }
  @include bp425 {
    width: 100%;

    &.singin {
      position: relative;
      flex-direction: column;
      top: unset;
      left: unset;
      min-height: auto;
      transform: unset;
    }
    &.pass {
      position: relative;
      height: 100%;
      top: unset;
      left: unset;
      min-height: auto;
      transform: unset;
    }
  }

  .login_area {
    position: relative;
    display: block;
    width: 100%;
  }
}

.login_top {
  position: relative;
  width: 100%;

  padding: 1.2em 0;
  background: $bg-white;
  img {
    display: block;
    margin: 0 auto;
  }
  .goHome {
    position: absolute;
    top: 50%;
    right: $em-lg;
    transform: translateY(-50%);
  }
}

.login_box {
  position: relative;
  padding: $em-xl $em-lg $em-lg;
  width: 100%;
  height: 643px;
  @include bp768 {
    height: unset;
  }
  @include bp425 {
    height: unset;
  }
  background-color: $bg-white;
  h2 {
    font-size: $em-xl;
    font-weight: 500;
    text-align: center;
  }
  .tab_area {
    margin: 1.15em 0 1.25em;
    ul {
      @include flex-center;
      @include common-border;
      li {
        width: 50%;
        background-color: $li-white;
        &:first-child {
          @include common-border-right;
        }
        &.active {
          background-color: $bg-white;
        }
        a {
          display: block;
          padding: $em-md;
          font-size: 0.83em;
          text-align: center;
        }
      }
    }
  }
  .tab_content {
    .find_tit {
      display: block;
      margin-bottom: $em-xl;
      font-size: $em-md + $em-sm;
      text-align: center;
    }
    .findInput_area {
      display: block;
      &.confirm {
        display: none;
      }
    }
    .findConfirm_area {
      display: none;
      &.confirm {
        display: block;
      }
      > span {
        display: block;
        margin-bottom: $em-xl;
        font-size: 0.75em;
        text-align: center;
        b {
          color: $bold-color;
          font-size: $em-xl;
        }
      }
    }
    .input_area {
      margin-bottom: $em-lg + $em-md;
      input {
        &.inp_text {
          display: block;
          margin-bottom: $em-md;
          padding: $em-md $em-lg;
          width: 100%;
          font-size: $em-md + $em-sm;
          @include common-border;
        }
      }
      label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .inp_chk {
          width: $em-md + $em-sm;
          height: $em-md + $em-sm;
          background-color: $bg-white;
          border-radius: 0;
        }
        span {
          margin-left: $em-md;
          font-size: $em-md + $em-sm;
          line-height: $em-lg;
        }
      }
    }
  }
  &.join {
    .tab_area {
      margin: 0 0 1.25em;
      ul {
        @include flex-center;
        margin: 0 auto;
        width: 30em;
        @include common-border;
        li {
          width: 50%;
          background-color: #f5f5f5;
          &.active {
            background-color: #263050;
            a {
              color: #fff;
            }
          }
          a {
            display: block;
            padding: 0.5em;
            font-size: 0.83em;
            text-align: center;
          }
        }
      }
    }
  }
}

.find {
  .login_box {
    height: auto;
  }
}
.banner {
  .loginRight {
    width: 450px;
    height: 643px;
    overflow: hidden;
    img {
      @include w-h-100;
    }
  }
  .loginBottom {
    a {
      img {
        width: 100%;
      }
    }
  }
}

.btn_areaLogin {
  &.login {
    @include flex-center;
    flex-wrap: wrap;
    > a {
      display: block;
      margin-bottom: 0.5em;
      padding: 0.5em;
      width: 100%;
      font-size: 0.75em;
      text-align: center;
      box-sizing: border-box;
      @include common-border;
    }
    > button {
      color: $bg-white;
      background-color: $bold-color;
      border: none;
      display: block;
      margin-bottom: 0.5em;
      padding: 0.5em;
      width: 100%;
      font-size: 0.75em;
      text-align: center;
      box-sizing: border-box;
    }
    a {
      &.half {
        @include wCalc(100%, 0.5em, 2);
        font-size: 0.6em;
      }
      &.findID {
        margin-right: $em-md;
      }
    }
    .snsLogin_area {
      @include flex-center;
      margin: 0.5em 0 0.75em;
      padding: 0.5em 0.25em;
      width: 100%;
      background-color: #f5f5f5;
      @include common-border;
      > span {
        margin-right: 1em;
        font-size: 0.75em;
      }
      > a {
        margin: 0 0.25em;
      }
    }
  }
}

.join_area {
  &.joinType {
    margin-top: 1.15em;
    ul {
      li {
        @include flex-center;
        flex-wrap: wrap;
        margin-bottom: 0.5em;
        padding: 1em;
        background-color: #f5f5f5;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          @include flex-center;
          flex-wrap: wrap;
          &.snsBtn {
            margin: 0 0.5em;
          }
        }
        span {
          margin-top: 0.5em;
          width: 100%;
          letter-spacing: -0.05em;
          text-align: center;
        }
        p {
          @include flex-center;
          font-size: 0.75em;
          img {
            margin-left: 0.25em;
          }
        }
      }
    }
  }
}
/* join */

.join_box {
  position: relative;
  padding: 1.25em 0.25em 1em;
  width: 900px;
  height: 643px;
  background-color: #fff;
  overflow-y: auto;
  @include bp425 {
    height: unset;
  }
  h2 {
    margin-bottom: 0.5em;
    padding-bottom: 0.25em;
    font-size: 1.25em;
    font-weight: 500;
    border-bottom: 1px solid #b2b2b2;
    &.scdTit {
      margin-top: 0.9em;
    }
  }
  .tab_area {
    margin: 0 0 1.25em;
    ul {
      @include flex-center;
      margin: 0 auto;
      width: 30em;
      @include common-border;
      @include bp768 {
        width: auto;
      }
      @include bp425 {
        width: auto;
      }
      li {
        width: 50%;
        background-color: #f5f5f5;
        &.active {
          background-color: #263050;
          a {
            color: #fff;
          }
        }
        a {
          display: block;
          padding: 0.5em;
          font-size: 0.83em;
          text-align: center;
        }
      }
    }
  }
  .tab_content {
    padding: 0 1.5em;
  }
  .terms_list {
    padding: 1em 0.5em;
    border: 1px solid #b2b2b2;
    background-color: #fafafa;
    li {
      &.all_chk {
        margin-bottom: 0.25em;
      }
      &.chk_list {
        padding-left: 1em;
        label {
          font-size: 0.8em;
        }
        a {
          span {
            font-size: 0.8em;
          }
        }
      }
    }
    .customChk {
      font-size: 0.85em;
      @media screen and (max-width: 700px) {
        flex-direction: column;
        align-items: flex-start;
      }
      > a {
        @media screen and (max-width: 700px) {
          width: 100%;
          text-align: right;
          margin: 0.2em 0 1em 0;
        }
      }
      > label {
        @media screen and (max-width: 700px) {
          width: 100%;
        }
      }
    }
  }
  .terms_area {
    margin-bottom: 1em;
  }
  .join_info {
    > ul {
      border: 1px solid #b2b2b2;
      background-color: #fafafa;
      > li {
        padding: 0.5em;
        border-bottom: 1px solid #b2b2b2;

        &:last-child {
          border-bottom: none;
        }
        .input_area {
          // @include flex-center;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          font-size: 0.75em;

          @media screen and (max-width: 610px) {
            flex-direction: column;
          }
          &.address {
            justify-content: flex-end;
            .bot {
              margin-top: 0.5em;
            }
          }
          &.subComp {
            font-size: 0.9em;
          }
          > .warning {
            font-size: 0.8em;
            color: red;
          }
          > label {
            position: relative;
            display: block;
            margin-right: 1%;
            padding-left: 1em;
            padding-right: 4%;
            width: 19% !important;
            font-size: 0.9em;
            border-right: 1px solid #b2b2b2;

            @media screen and (max-width: 610px) {
              width: 100% !important;
              border-right: 0;
              margin-bottom: 0.5em;
            }

            &.essential {
              &:after {
                position: absolute;
                content: "※";
                top: 0;
                left: 0.5em;
                color: red;
                font-size: 0.5em;
              }
            }
          }
          > .slt_label {
            width: auto !important;
            padding: 0 0 0 1.5em;
            border: none;
          }
          > input {
            padding: 0;
            font-size: 0.9em;
            &[type="text"] {
              padding: 0.1em;
              border: none;
              // background: none;
              font-size: 1em;
              @include common-input-border;
            }
            &[type="password"] {
              padding: 0.1em;
              border: none;
              @include common-input-border;
              // background: none;
            }
          }
          > select {
            padding: 0;
            font-size: 0.9em;
            border: none;
            background: none;
          }
          > a {
            &.btn_overlap {
              padding: 0.2em;
              color: #fff;
              font-size: 0.75em;
              text-align: center;
              background-color: #646464;
            }
          }
          .customChk {
            > label {
              margin-right: 1em;
              width: auto;
            }
          }
          .addonButton {
            width: 80%;
            display: flex;
            justify-content: space-between;
            > input.inp_text {
              border: none;
              font-size: 0.9em;
              background: none;
            }
            @media screen and (max-width: 610px) {
              width: 100% !important;
            }
            > input.inp_text {
              border: none;

              font-size: 1em;
              @include common-input-border;
            }
            > ul {
              width: 100%;
              input {
                font-size: 1em;
              }
            }
            .searchBtn {
              background-color: #263050;
              font-size: 0.9em;
              padding: 0.3em;
              color: white;
              border: none;
            }
          }
        }
      }
    }
  }
}
.btn_areaLogin {
  &.join {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1em;
    > button {
      display: block;
      margin-bottom: 0.5em;
      padding: 0.5em;
      width: 100%;
      font-size: 0.75em;
      text-align: center;
      box-sizing: border-box;
      color: #fff;
      background-color: #263050;
      border: none;
    }
    > a {
      display: block;
      margin-bottom: 0.5em;
      padding: 0.5em;
      width: 100%;
      font-size: 0.75em;
      text-align: center;
      box-sizing: border-box;
    }
  }
}
