@import "./media.scss";
@import "./common.module.scss";

$bp1200_max_width: 1012px;
$bp1024_max_width: 768px;
$bp768_max_width: 413px;

.sub_container {
  &.channel {
    section {
      &.boardList {
        @include bp1200 {
          width: $bp1200_max_width;
        }
        @include bp1024 {
          width: $bp1024_max_width;
        }
        @include bp768 {
          width: 100%;
        }
        @include bp425 {
          width: 100%;
        }
        .idx_board {
          @include bp1200 {
            width: 100%;
          }
          @include bp1024 {
            width: 100%;
          }
          @include bp768 {
            width: 100%;
          }
          @include bp425 {
            width: 100%;
          }
          &.weekly {
            @include bp1200 {
              margin-right: 0;
            }
            @include bp1024 {
              margin-right: 0;
            }
            @include bp768 {
              padding: 0 1em 3em 1em;
            }
            @include bp425 {
              padding: 0 1em 3em 1em;
            }
          }
        }
        .idx_interview {
          &.ch_index {
            @include bp1200 {
              width: $bp1200_max_width;
            }
            @include bp1024 {
              width: $bp1024_max_width;
            }
            @include bp768 {
              width: 100%;
            }
            @include bp425 {
              width: 100%;
            }
          }

          &.host {
            @include bp1200 {
              width: 100%;
              margin-right: 0;
            }
            @include bp1024 {
              width: 100%;
              margin-right: 0;
            }
            @include bp768 {
              width: 100%;
              margin: 0 1em 0em 1em;
            }
            @include bp425 {
              width: 100%;
              margin: 0 1em 0em 1em;
            }
            > ul.thumbList {
              @include bp425 {
                width: 100%;
                flex-direction: column;
              }
              > li {
                @include bp425 {
                  width: 100%;
                  margin-right: 0;
                }
              }
            }
          }
        }
      }

      &.interview {
        @include bp768 {
          padding: 3em 1em;
        }
        @include bp425 {
          padding: 3em 1em;
        }
        .idx_interview {
          @include bp1200 {
            width: $bp1200_max_width;
          }
          @include bp1024 {
            width: $bp1024_max_width;
          }
          > ul.thumbList {
            @include bp425 {
              width: 100%;
            }
            > li {
              @include bp425 {
                margin-right: 0;
                margin-bottom: 1em;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .contents {
    .board_search {
      > select {
        @include common-input-border;
      }
      > input {
        @include common-input-border;
      }
    }
    .board_list {
      > ul {
        > li {
          &.th {
            @include bp425 {
              display: none;
            }
          }
          &.td {
            > a {
              @include bp425 {
                display: flex;
                flex-direction: column;

                align-items: flex-start;
              }
              > span {
                @include bp425 {
                  text-align: left;
                  margin: 0 2.5%;

                  padding: 1em 0;
                  &:first-child {
                    text-align: center;
                    width: 15% !important;
                    padding: 0.2em 0;
                  }
                  &:last-child {
                    padding: 0;
                    text-align: right;
                    color: rgba(0, 0, 0, 0.7);
                  }
                  width: 95% !important;
                }
              }
            }
          }
        }
      }

      // 수상작 갤러리
      &.gallery {
        .btnArea {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .btn {
            border: none;
            background: #565656;
            color: white;
            padding: 0.2em 0.5em;
            cursor: pointer;
          }
        }
        .thumbList {
          li {
            a {
              > span {
                &.thumb_sub {
                  font-size: 0.8em;
                  color: #2465aa;
                }
                &.thumb_title {
                  font-size: 1em;
                }
                &.thumb_sub_right {
                  font-size: 0.8em;
                  text-align: right;
                }
              }
              > p {
                &.thumb_date {
                  text-align: right;
                }
              }
            }
          }
          @include bp425 {
            justify-content: center;
          }
          > li {
            @include bp425 {
              justify-content: center;
              align-items: center;
              margin: 0.5em;
              width: calc((100% - 3em) / 2);
            }
          }
        }
      }
    }
  }
}
