@import "./media.scss";

$bp1200_max_width: 1012px;
$bp1024_max_width: 768px;
$bp768_max_width: 413px;

.sub_container {
	&.channel {
		section.section {
			&.boardList {
				@include bp1200 {
					width: $bp1200_max_width;
					&:last-child {
						padding: 0 0 3em 0;
					}
				}
				@include bp1024 {
					width: $bp1024_max_width;
					&:last-child {
						padding: 0 0 3em 0;
					}
				}
				@include bp768 {
					width: 100%;
					padding-bottom: 0;
					&:first-child {
						padding-bottom: 0;
					}
					&:last-child {
						padding: 0 0 3em 0;
					}
				}
				@include bp425 {
					width: 100%;
					padding-bottom: 0;
					&:first-child {
						padding-bottom: 0;
					}
					&:last-child {
						padding: 0 0 3em 0;
					}
				}

				.idx_board {
					.boardList {
						li {
							a {
								span.brd_title {
									width: calc((100% - 5.7em));
								}
							}
						}
					}
					&.weekly {
						@include bp1200 {
							width: $bp1200_max_width;
							&:first-child {
								margin-right: 0;
							}
							&:last-child {
								margin-right: 0;
							}
						}
						@include bp1024 {
							width: $bp1024_max_width;
							&:first-child {
								margin-right: 0;
							}
							&:last-child {
								margin-right: 0;
							}
						}
						@include bp768 {
							width: 100%;
							padding: 0 1em 3em 1em;
							margin-right: 0;
							margin-bottom: 0;
						}
						@include bp425 {
							width: 100%;
							padding: 0 1em 3em 1em;
							margin-right: 0;
							margin-bottom: 0;
						}
						small {
							&.small {
								width: 90%;
							}
						}
					}
					&.freeboard {
						@include bp1200 {
							width: $bp1200_max_width;
						}
						@include bp1024 {
							width: $bp1024_max_width;
						}
						@include bp768 {
							width: 100%;
							padding: 0 1em 3em 1em;
						}
						@include bp425 {
							width: 100%;
							padding: 0 1em 3em 1em;
						}
						small {
							&.small {
								width: 90%;
							}
						}
					}
				}
			}
		}
	}

	// 커뮤니티 각 메뉴별 데이터 리스트
	.contents {
		.board_list {
			> ul {
				> li {
					&.th {
						@include bp425 {
							display: none;
						}
					}
					&.td {
						// dtalks
						> a {
							@include bp425 {
								flex-direction: column;
								align-items: flex-start;
							}
							> span {
								font-size: 1em;

								@include bp425 {
									text-align: left;
									width: 100% !important;
								}

								&.brd_cate {
									@include bp425 {
										text-align: center;
										width: 100px !important;
										margin-left: 0;
									}
								}
								&.brd_title {
									@include bp425 {
										padding-left: 0;
										padding: 0.5em 0;
									}
								}
								&.brd_name {
									@include bp425 {
										text-align: right;
									}
								}
								&.brd_info {
									&.brd_date {
										@include bp425 {
											text-align: right;
										}
									}
								}
								&.brd_answer {
									@include bp425 {
										text-align: right;
									}
								}
							}
						}

						// teamplay
						@include bp425 {
							flex-direction: column;
							align-items: flex-start;
						}
						> span {
							font-size: 1em;

							@include bp425 {
								text-align: left;
								width: 100% !important;
							}

							&.brd_cate {
								@include bp425 {
									text-align: center;
									width: 100px !important;
									margin-left: 0;
								}
							}
							&.brd_title {
								@include bp425 {
									padding-left: 0;
									padding: 0.5em 0;
								}
							}
							&.brd_name {
								@include bp425 {
									text-align: right;
								}
							}
							&.brd_info {
								&.brd_date {
									@include bp425 {
										text-align: right;
									}
								}
							}
							&.brd_answer {
								@include bp425 {
									text-align: right;
								}
							}
						}
					}
				}
			}
		}
	}
}
