@import "./media.scss";
@import "./scroll.module.scss";

.paging_area {
  @include bp425 {
    overflow: scroll;
    justify-content: flex-start;
    @include hide-scroll-bar;
  }
  .paging_sub {
    @include bp425 {
      width: 1200px;
      justify-content: flex-start;
    }
  }
  .paging {
    display: flex;
    @include bp425 {
      width: 1200px;
    }
  }
  &.contest {
    a {
      color: white;
    }
  }
}

.replyPaging {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgb(251, 251, 251) !important;
  padding-bottom: 1em;
  border-top: unset !important;

  > .replyLi {
    padding: 0.1em 0.5em;
    border: 1px solid #dcdcdc;
    margin: 0 0.3em;
    border-radius: 3px;
    cursor: pointer;
    // padding: 0.3em;

    &.active {
      background-color: #3f4453;
      color: white;
    }
  }
}
