@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading_spinner {
  width: 1em;
  height: 1em;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #383636;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
