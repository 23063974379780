@import "./media.scss";

$bp_1200_max_width: 1012px;
$bp_1024_max_width: 756px;

.subVisual {
	@include bp425 {
		> span {
			font-size: 2em !important;
		}
	}
}
.sub_container {
	.btn_area {
		&.free_reg {
			@include bp425 {
				flex-direction: column;
				font-size: 0.6em;
				> a {
					&:first-child {
						@include bp425 {
							margin-bottom: 1em;
						}
					}
				}
			}
		}
	}
	.contents {
		&.mypage {
			@include bp1200 {
				max-width: $bp_1200_max_width;
			}
			@include bp1024 {
				max-width: $bp_1024_max_width;
			}

			&.middle {
				@include bp768 {
					padding: 3em 1em;
				}
				.freeReg_title {
					@include bp425 {
						> span {
							font-size: 1.5em;
						}
					}
				}
			}
			&.bottom {
				@include bp1024 {
					padding-top: 1em;
				}
				@include bp768 {
					padding: 3em 1em;
				}
				@include bp425 {
					padding: 3em 1em;
					.btn_area {
						&.us_area {
							margin-top: 2em;
							flex-direction: column;
							> a {
								&:first-child {
									margin-bottom: 1em;
								}
							}
						}
					}
				}
			}
			.upgrade_service {
				@include bp1024 {
					flex-direction: column-reverse;
				}
				@include bp768 {
					flex-direction: column-reverse;
				}
				@include bp425 {
					flex-direction: column-reverse;
				}

				.text_area {
					@include bp1024 {
						width: 100%;
					}
					@include bp768 {
						width: 100%;
					}
					@include bp425 {
						width: 100%;
					}
				}
				.img_area {
					@include bp1024 {
						width: 100%;
						display: flex;
						justify-content: center;
						padding-bottom: 1em;
					}
					@include bp768 {
						width: 100%;
						display: flex;
						justify-content: center;
						padding-bottom: 1em;
					}
					@include bp425 {
						width: 100%;
						display: flex;
						justify-content: center;
						padding-bottom: 1em;
					}
				}
			}
		}
	}
}
