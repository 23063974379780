@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
.notosanskr * {
  font-family: "Noto Sans KR", sans-serif;
}

/* common */
* {
  box-sizing: border-box;
}

body,
th,
td,
input,
select,
textarea,
button {
  font-size: 40px;
  line-height: 1.5;
  letter-spacing: -0.015em;
  font-family: "Noto Sans KR", "Malgun Gothic", "ë§‘ì€ ê³ ë”•", sans-serif;
  color: #0b0405;
}

div,
header,
section,
nav,
input,
ul,
li {
  box-sizing: border-box;
}

body,
th,
td,
p,
li,
span,
h1,
h2,
h3,
h4,
h5 {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

body,
a,
button,
input,
label,
select,
div,
li {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a,
p,
li,
span,
div,
h1,
h2,
h3,
h4,
h5,
input,
select,
textarea,
button,
dl,
dd,
dt {
  word-break: keep-all;
}

a {
  color: #0b0405;
}

dl {
  color: inherit; /* Inherit text color of parent element. */
  text-decoration: none; /* Remove underline. */
  /* Additional css `propery: value;` pairs here */
}

img {
  width: auto;
  max-width: 100%;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  background-color: #2b2f3b;
  -ms-overflow-style: none;
}
html,
body,
.wrap {
  position: relative;
}
html.open,
body.open,
.wrap.open {
  overflow: hidden;
}

.w100 {
  width: 100% !important;
}
.w95 {
  width: 95% !important;
}
.w90 {
  width: 90% !important;
}
.w85 {
  width: 85% !important;
}
.w80 {
  width: 80% !important;
}
.w75 {
  width: 75% !important;
}
.w70 {
  width: 70% !important;
}
.w65 {
  width: 65% !important;
}
.w60 {
  width: 60% !important;
}
.w55 {
  width: 55% !important;
}
.w50 {
  width: 50% !important;
}
.w45 {
  width: 45% !important;
}
.w40 {
  width: 40% !important;
}
.w35 {
  width: 35% !important;
}
.w30 {
  width: 30% !important;
}
.w25 {
  width: 25% !important;
}
.w20 {
  width: 20% !important;
}
.w15 {
  width: 15% !important;
}
.w10 {
  width: 10% !important;
}
.w5 {
  width: 5% !important;
}

.wrap {
  position: relative;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100vh;
}

.header {
  position: relative;
}

/* 210422 snb 영역 추가 */
.header .snb_wrap {
  position: relative;
  padding: 0.5em 0;
  background-color: #fff;
  border-radius: 0.5em 0 0 0.5em;
}
.header .snb_wrap .snb_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 0.25em;
  width: 100%;
  max-width: 1200px;
}

.header .snb_wrap .sns_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.header .snb_wrap .sns_wrap a {
  /* margin-right: 0.5em; */
  margin-top: 0.5em;
  width: 1.25em;
}
.header .snb_wrap .btn_coincenter {
  font-size: 0.66em;
}

.header .header_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 0.25em;
  width: 100%;
  max-width: 1200px;
  height: 3.5em;
}
.header .logo {
  width: 6.25em;
  z-index: 11;
}
.header .logo .wlogo,
.header.hc .ab_right .w_ico {
  display: block;
}
.header .logo .clogo,
.header.hc .ab_right .c_ico {
  display: none;
}

.header a.btnBack {
  display: block;
  width: 1.5em;
}
.header a.btnHome {
  display: block;
  width: 2.7em;
}

.header .ab_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header .ab_left a.goPrev {
  display: block;
  width: 1.1em;
  height: 1.6em;
  background: url("data:image/svg+xml,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='44' height='64' x='0px' y='0px' viewBox='0 0 44 64' style='enable-background:new 0 0 44 64%3b' xml:space='preserve'%3e%3cpath fill='%23232323' d='M41.4%2c64c-0.5%2c0-1.1-0.2-1.6-0.5L1.1%2c34.2C0.4%2c33.7%2c0%2c32.9%2c0%2c32s0.4-1.7%2c1.1-2.2L39.8%2c0.5c1.2-0.9%2c2.8-0.6%2c3.7%2c0.6c0.9%2c1.2%2c0.6%2c2.9-0.6%2c3.8L7.1%2c32l35.8%2c27c1.2%2c0.9%2c1.4%2c2.6%2c0.6%2c3.8C43%2c63.6%2c42.2%2c64%2c41.4%2c64z'/%3e%3c/svg%3e")
    no-repeat;
  background-size: cover;
}

.header .ab_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header .ab_right button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .ab_right > a {
  margin-left: 1em;
}

.header .logo .clogo,
.header .ab_right .c_ico {
  display: none;
}

.header .subTitle {
  width: 75%;
  color: #232323;
  font-size: 1.7em;
  font-weight: 500;
  text-align: center;
}

/* main header */
.header.main {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  z-index: 100;
}

.header.main .menuBtn .menu-btn .line {
  color: rgba(255, 255, 255, 1);
  background: rgba(255, 255, 255, 1);
}
.header.main .menuBtn .menu-btn .hid {
  background: rgba(255, 255, 255, 0);
}
.header.main .menuBtn .menu-btn.gnbClose .hid {
  background: rgba(255, 255, 255, 1);
}

.btn_area {
  display: block;
}
.btn_area.large {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.btn_area .submit {
  display: block;
  padding: 1em 0;
  width: 100%;
  color: #fff;
  font-size: 1.4em;
  text-align: center;
  background-color: #29407c;
  border: none;
}

/* navigation */
.main_nav {
  width: 30.5em;
}
.main_nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main_nav ul li a {
  position: relative;
  color: #fff;
  font-size: 0.66em;
}
.main_nav.black ul li a {
  position: relative;
  color: #323232;
  font-size: 0.66em;
}
.main_nav ul li:hover {
  transition: all 0.5s;
  transform: translateY(-10px);
}
.contestSlider .slick-list .slick-track .slick-slide {
  /* width: 140px !important; */
}
/* .main_nav ul li:hover a:before {
	border: 1px solid red;
	width: 80px;
} */

.main_nav ul li.active a:after {
  position: absolute;
  content: "";
  bottom: -0.5em;
  left: 0;
  width: 100%;
  height: 0.15em;
  background-color: #e9360c;
}

/* hamberger menu icon */
.menuBtn {
  margin-left: 1em;
  width: 1em;
  height: 0.75em;
  z-index: 10;
  /*transition:0.5s;*/
}
.menuBtn .menu-btn {
  position: relative;
  width: 100%;
  height: 100%;
  float: right;
  cursor: pointer;
}
.menuBtn .menu-btn .line {
  background: #0b0405;
  display: block;
  width: 100%;
  height: 0.083em;
  float: left;
  clear: right;
  margin: 0.25em 0;
}
.menuBtn .menu-btn .hid {
  transform: rotateZ(0deg);
  /*transition:all .2s ease;*/
  position: absolute;
  background: rgba(255, 255, 255, 0);
  margin: 0.25em 0 0 0;
}
.menuBtn .menu-btn.gnbClose .hid {
  background: rgba(255, 255, 255, 1);
  transform: rotateZ(135deg);
}

.menuBtn .menu-btn .second {
  transform: rotateZ(0deg);
  /*transition:all .5s ease;*/
  background: #0b0405;
}
.menuBtn .menu-btn .second.animate {
  transform: rotateZ(225deg);
  /*transition:all .5s ease;*/
  background: #fff;
}
.menuBtn .menu-btn .first {
  margin: 0;
}
.menuBtn .menu-btn .first,
.menuBtn .menu-btn .third {
  /*transition:all .5s ease-out;*/
}
.menuBtn .menu-btn .third {
  left: 0;
  float: left;
  margin: 0;
}
.menuBtn .menu-btn .first.animate,
.menuBtn .menu-btn .third.animate {
  width: 0; /*transition:all .3s ease-out;*/
}
/* hamberger menu icon */

.gnb {
  display: block;
  overflow: hidden;
  width: 100vw;
  height: 0;
  background-color: rgba(0, 0, 0, 0.6);
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  transition: 0.3s;
}

.gnb.active {
  height: 100vh;
  transition: 0.3s;
}

.gnbOpen {
  display: block;
  line-height: 0;
}

.gnb > nav {
  position: relative;
  display: block;
  padding-top: 3.5em;
  width: 100vw;
  height: 100vh;
  background-color: rgba(43, 47, 59, 0.85);
}

.gnb > nav .logoutArea {
  position: absolute;
  top: 0.9em;
  left: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 49.5em;
  transform: translateX(-50%);
}
.gnb > nav .btn_logout {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5em;
  padding: 0.25em 0.5em;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.gnb > nav .btn_logout span {
  font-size: 0.65em;
  color: #fff;
}

.gnb > nav .gnbTop {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 1.5em 1.5em 2em 2em;
  height: 10.5em;
  background: rgb(62, 54, 86); /* Old browsers */
  background: -moz-linear-gradient(
    45deg,
    rgba(62, 54, 86, 1) 0%,
    rgba(84, 76, 110, 1) 80%,
    rgba(84, 76, 110, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    45deg,
    rgba(62, 54, 86, 1) 0%,
    rgba(84, 76, 110, 1) 80%,
    rgba(84, 76, 110, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    45deg,
    rgba(62, 54, 86, 1) 0%,
    rgba(84, 76, 110, 1) 80%,
    rgba(84, 76, 110, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e3656', endColorstr='#544c6e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.gnb > nav .gnbTop .loginInfo a {
  display: block;
  padding: 0.5em 0 0 3.25em;
  color: #fff;
  line-height: 1;
}

.gnb > nav .gnbTop .loginInfo a.userInfo {
  display: none;
  /* background: url(/src/assets/img/ic_person.png) no-repeat left top; */
  background-size: 2em;
}

.gnb > nav .gnbTop .loginInfo.login a.userInfo {
  display: block;
}

.gnb > nav .gnbTop .loginInfo a span.userName {
  font-size: 1.8em;
  font-weight: 500;
}

.gnb > nav .gnbTop .loginInfo a span.memberType {
  margin-top: 0.75em;
  font-size: 1.125em;
  font-weight: 500;
  color: #aeaeae;
}

.gnb > nav .gnbTop .loginInfo a.goLogin {
  display: block;
  /* background: url(/src/assets/img/ic_lock.png) no-repeat left top; */
  background-size: 2em;
}

.gnb > nav .gnbTop .loginInfo.login a.goLogin {
  display: none;
}

.gnb > nav .gnbTop .loginInfo a span {
  display: block;
}

.gnb > nav .gnbTop span.person {
  /* background: url(/src/assets/img/ic_personW.png) no-repeat left; */
}

.gnb > nav > .nav-category {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1188px;
  transform: translate(-50%, -50%);
}

.gnb ul.gnbBox {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
  padding-left: 3em;
  padding-right: 3em;
}

.gnbBox > li {
  margin-right: 0.5em;
}
.gnbBox > li:last-child {
  margin-right: 0;
}

.gnbBox > li > a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3em;
  color: #fff;
  font-size: 1.3em;
}
.gnbBox > li > a span {
  position: relative;
}
.gnbBox > li > a span:after {
  position: absolute;
  content: "";
  bottom: 6px;
  right: -1em;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  opacity: 0;
  background-color: #e9360c;
  transition: 0.3s;
}

.gnbBox > li:hover > a span:after {
  right: -12px;
  opacity: 1;
  transition: 0.3s;
}

.gnbBox > li > a > span {
  font-weight: 500;
}

.gnbBox > li > ul {
  display: block;
}

.gnbBox > li > ul > li {
  display: block;
  width: 100%;
  box-sizing: border-box;
  transition: 0.3s;
}

.gnbBox > li > ul > li:last-child {
  border-bottom: none;
}

.gnbBox > li > ul > li > a {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25em 0.5em;
  color: #fff;
  font-weight: 300;
  text-align: left;
  transition: 0.3s;
}
.gnbBox > li > ul > li > a:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 0.2em;
  height: 0.2em;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-50%);
}

.gnbBox > li > ul:hover > li {
  opacity: 0.4;
  transition: 0.3s;
}

.gnbBox > li > ul:hover > li:hover {
  opacity: 1;
  transition: 0.3s;
}
.gnbBox > li > ul:hover > li:hover > a:before {
  background-color: rgba(255, 255, 255, 0.7);
}

.gnbBox > li > ul > li > a span {
  display: block;
  font-size: 0.7em;
  font-weight: 300;
  z-index: 1;
}

.gnbBox > li > a img.navIco {
  margin-right: 1.5em;
  width: 2.2em;
}

.gnbBox > li:first-child > a img.navIco,
.gnbBox > li:nth-child(2) > a img.navIco {
  margin-top: -0.2em;
}

.gnbBox > li > a img.navArrow {
  margin-left: auto;
  width: 0.675em;
  line-height: 0;
  opacity: 0.4;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.gnbBox > li.active > a img.navArrow {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  opacity: 1;
}

.gnbBox > li > ul > li > a img.navArrow {
  width: 0.675em;
  opacity: 0.15;
}

.gnb > nav .gnbBottom {
  position: absolute;
  left: 0;
  bottom: 2.25em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.75em;
  width: 100vw;
}

.gnb > nav .gnbBottom span.asCenter {
  font-size: 1.2em;
  font-weight: 500;
}

.gnb > nav .gnbBottom span.asCenter span {
  font-weight: 600;
}

.gnb > nav .gnbBottom a.logOut {
  padding-right: 1.5em;
  font-size: 1.2em;
  font-weight: 500;
  /* background: url("/src/assets/img/ic_logout.png") no-repeat right 60%; */
  background-size: 1em;
}

/* board paging area */
.paging_area {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}
.paging_area a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.25em;
  width: 2em;
  height: 2em;
  font-size: 0.65em;
  border: 1px solid #969696;
  transition: 0.3s;
}
.paging_area a:hover {
  background-color: #f0f0f0;
}
.paging_area a.active {
  color: #fff;
  background-color: #263050;
  border-color: #263050;
}

/* follow link */
.followLink {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6em;
  z-index: 5;
}

.followLink.end {
  position: relative;
}

.followLink a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  color: #fff;
  font-size: 1.35em;
}

.followLink a:first-child {
  background-color: #29407c;
}

.followLink a:last-child {
  background-color: #0b0405;
}

.followLink a img {
  margin-right: 0.75em;
  width: 1.1484em;
}

/* floating banner */
.floating_area {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 1em 0;
  width: 100%;
  height: auto;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
  transition: all 0.3s;
}

.floating_area.end {
  padding: 0;
  opacity: 0;
  top: auto;
  height: 0;
}

.floating_area .floating_cont {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 6px;
  width: 100%;
  max-width: 1200px;
}

.floating_area .floating_cont .text_area span {
  display: table;
  font-size: 1em;
  line-height: 1em;
  color: #fff;
}
.floating_area .floating_cont .text_area span b {
  color: #f44224;
  font-weight: 500;
  cursor: pointer;
}
.floating_area .floating_cont .text_area button {
  padding: 0;
  font-size: 0.55em;
  line-height: 1em;
  color: rgba(255, 255, 255, 0.5);
  background: none;
  border: none;
}

.floating_area .floating_cont .input_area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.floating_area .floating_cont .input_area input,
.floating_area .floating_cont .input_area button {
  margin-right: 0.5em;
  padding: 0.25em;
  font-size: 0.75em;
  border: none;
}
.floating_area .floating_cont .input_area button {
  padding: 0.25em 1em;
  color: #fff;
  background-color: #f44224;
}

/* footer */
.footer {
  padding: 2.5em 0;
  width: 100%;
  background-color: #222530;
}
.footer .footLink {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto 1.2em;
  padding: 0 0.25em;
  width: 100%;
  max-width: 1200px;
}
.footer .footLink a {
  position: relative;
  display: block;
  margin-right: 1em;
  padding-right: 1.2em;
  color: #fff;
  font-size: 0.6em;
}
.footer .footLink a:after {
  position: absolute;
  right: 0;
  content: "I";
  font-weight: 300;
  color: rgba(255, 255, 255, 0.3);
}
.footer .footLink a:last-child:after {
  display: none;
}

.footer footer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0 6px;
  width: 100%;
  max-width: 1200px;
  color: #fff;
  box-sizing: border-box;
}

.footer footer dd {
  width: 13em;
}

.footer footer .csArea {
  width: 20.3em;
}

.footer footer a.terms {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.125em;
  font-weight: 500;
  color: #fff;
}

.footer footer span.foot_Cname {
  width: 7.83em;
}

.footer footer dd {
  margin: 0 3.6em;
}

.footer footer dd dl {
  display: block;
  color: #898989;
  font-size: 0.5em;
  line-height: 1.7em;
  letter-spacing: -0.06em;
}

.footer footer dd dl:last-child {
  line-height: 2em;
}

.footer footer .csArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 28.03em);
}
.footer footer .csArea h3 {
  position: relative;
  width: 100%;
  font-size: 0.75em;
  line-height: 1em;
  margin-bottom: 0.25em;
}
.footer footer .csArea h3:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #969696;
}
.footer footer .csArea h3 span {
  position: relative;
  display: inline-block;
  padding-right: 1em;
  background-color: #222530;
  z-index: 1;
  box-sizing: border-box;
}
.footer footer .csArea ul {
  width: 100%;
}
.footer footer .csArea ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.25em 0;
}
.footer footer .csArea ul li:nth-child(2) {
  margin-bottom: 0.5em;
}
.footer footer .csArea ul li p.ca_tit {
  width: 10em;
  font-size: 0.583em;
}
.footer footer .csArea ul li p.workTime {
  font-size: 0.5em;
}
.footer footer .csArea ul li p.bNumb {
  font-size: 0.583em;
}
.footer footer .csArea ul li span.number {
  width: 8em;
  color: #ffd200;
  font-size: 1.16em;
  font-weight: 600;
  line-height: 1em;
}
.footer footer .csArea ul li:nth-child(2) span.number {
  width: auto;
}

/* popup */
.popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 8;
}
.popup .modal-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
}
.popup .modal-cont {
  position: relative;
  margin: 0 auto;
  padding: 1em;
  width: calc(100% - 2em);
  max-width: 800px;
  height: 70vh;
  background-color: #fff;
  z-index: 4;
}
.popup .modal-cont h2 {
  display: block;
  font-size: 0.9em;
  margin-bottom: 0.3em;
  color: #011627;
}
.popup .modal-cont .tableArea {
  display: none;
  margin: 0 auto 0.75em;
  width: 100%;
  border: 0.1em solid #dcdcdc;
  border-left: none;
  border-right: none;
}
.popup .modal-cont .tableArea.popView {
  display: block;
  height: calc(100% - 3em);
  font-size: 0.6em;
  overflow-y: auto;
}
.popup .modal-cont .tableArea.popView > p {
  margin-bottom: 0.5em;
}
.popup .modal-cont .tableArea.popView > ul > li {
  margin-bottom: 0.5em;
}
.popup .modal-cont .tableArea.popView > ul > li > ul {
  font-size: 0.8em;
}
.popup .modal-cont .tableArea ul li > span {
  display: block;
  padding: 0.375em;
  width: 7.5em;
  color: #24205c;
  text-align: center;
  background-color: #e5e6eb;
  border-bottom: 0.1em solid #fff;
  box-sizing: border-box;
}
.popup .modal-cont .tableArea ul li > span b {
  color: #fd0200;
}
.popup .modal-cont .tableArea ul li .input_area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.375em;
  width: 19.5em;
  border-bottom: 0.1em solid #e5e6eb;
}
.popup .modal-cont .tableArea ul li:last-child > span,
.popup .modal-cont .tableArea ul li:last-child > .input_area {
  border-bottom: none;
}
.popup .modal-cont .tableArea ul li .input_area input[type="text"],
.popup .modal-cont .tableArea ul li .input_area input[type="password"] {
  padding: 0.025em 0.25em;
  width: 100%;
  border: 0.1em solid #e5e6eb;
}

.popup .modal-cont .tableArea dl {
  margin: 1em 0;
}
.popup .modal-cont .tableArea dl dd {
  margin: 0.25em 0;
  font-size: 0.8em;
}

.popup .modal-cont .btn_area {
  margin: 0 auto;
  width: 100%;
}
.popup .modal-cont .btn_area button {
  display: block;
  width: 100%;
  line-height: 3em;
  color: #fff;
  background-color: #24205c;
  border: 0.1em solid #24205c;
}
.popup .modal-cont .close {
  position: absolute;
  top: 1.4em;
  right: 1em;
}
.popup .modal-cont .close img {
  width: 0.6em;
}

/* custom radio, checkbox */
.chk_list {
  font-size: 0.85em;
}
.customChk {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.customChk label {
  position: relative;
  display: block;
  padding-left: 1.5em;
  width: calc(100% - 8em);
  color: #323232;
  font-size: 0.9em;
}
.customChk label:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 1em;
  height: 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-50%);
}
.customChk label:after {
  position: absolute;
  content: "";
  opacity: 0;
  top: 50%;
  left: calc(0.2em + 1px);
  width: 0.6em;
  height: 0.6em;
  background-color: #263050;
  transform: translateY(-50%);
  transition: 0.3s;
}
.customChk input[type="radio"],
.customChk input[type="checkbox"] {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
}
.customChk input[type="radio"] + label:before,
.customChk input[type="radio"] + label:after {
  border-radius: 50%;
}
.customChk input[type="radio"]:checked + label:after,
.customChk input[type="checkbox"]:checked + label:after {
  opacity: 1;
  transition: 0.3s;
}
.customChk .radioActive + label:after,
.customChk .checkboxActive + label:after {
  opacity: 1;
  transition: 0.3s;
}

.customChk
  input[type="checkbox"]:checked
  + label:after
  .customChk
  > a.btn_terms {
  display: block;
  width: 7em;
  font-size: 1em;
  line-height: 1em;
}
.customChk > a.btn_terms span {
  display: block;
  padding: 0.2em 0.5em;
  font-size: 0.9em;
  text-align: center;
}

.icon-search-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 1em;
  width: 1em;
  position: relative;
  /*transition: width 0.2s ease-out;*/
  backface-visibility: hidden;
}
.icon-search-container.active {
  width: 9.6em;
  /*transition: width 0.2s ease-out;*/
}
.icon-search-container.active .fa-times-circle {
  opacity: 1;
}
.icon-search-container .fa-search {
  color: #fff;
  cursor: pointer;
}
.icon-search-container .fa-times-circle {
  opacity: 0;
  color: #d9d9d9;
  transition: opacity 0.2s ease-out;
  cursor: pointer;
}
.icon-search-container .search-input {
  cursor: default;
  margin: 0;
  padding: 0;
  width: 0;
  border: none;
  outline: none;
  font-size: 0.75em;
  background-color: rgba(255, 255, 255, 0.7);
  /*transition: width 0.2s ease-out;*/
  box-sizing: border-box;
  opacity: 0;
}
.icon-search-container.active .search-input {
  margin: 0 0.5em;
  padding: 5px;
  width: 200px;
  opacity: 1;
  /*transition: width 0.2s ease-out;*/
}

/* 200514 ì•½ê´€ íŒì—… ì¶”ê°€ */
[data-ml-modal] {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 999;
  width: 0;
  height: 0;
  opacity: 0;
}
[data-ml-modal]:target {
  width: auto;
  height: auto;
  opacity: 1;
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
}
[data-ml-modal]:target .modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
[data-ml-modal] .modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  width: 90vw;
  max-width: 660px;
  max-height: 70vh;
  overflow-x: hidden;
  z-index: 2;
  transform: translate(-50%, -50%);
}
.modal-dialog-lg {
  max-width: 820px !important;
}

[data-ml-modal] .modal-dialog > h3 {
  background-color: #eee;
  border-bottom: 1px solid #b3b3b3;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  padding: 0.8em 56px 0.8em 27px;
}
[data-ml-modal] .modal-content {
  background: #fff;
  padding: 23px 27px;
  height: calc(70vh - 76px);
  font-size: 0.75em;
  overflow-y: auto;
}
[data-ml-modal] .modal-close {
  position: absolute;
  top: 13px;
  right: 13px;
  color: #0085a6;
  background-color: #fff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
[data-ml-modal] .modal-close:hover {
  background-color: #0085a6;
  color: #fff;
  cursor: pointer;
}
[data-ml-modal] p:first-child,
[data-ml-modal] p:last-child {
  margin: 0;
}
@media (max-width: 767px) {
  [data-ml-modal] .modal-dialog {
    margin: 20% auto;
  }
}
/* End Modals ------------------------------*/
/* 20.05.27 topë²„íŠ¼ ì¶”ê°€ */
.chat {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5em;
  height: 2.5em;
  right: 1em;
  bottom: 4em;
  opacity: 1;
  transform: translateX(0);
  transition: 0.3s;
  z-index: 999;
  border-radius: 50%;
  background: linear-gradient(
    rgb(74, 38, 255) 0%,
    rgb(74, 38, 255) 50%,
    rgb(125, 38, 255) 100%
  ) !important;
}

.goTop {
  position: fixed;
  width: 2.5em;
  right: 1em;
  bottom: 0.5em;
  opacity: 1;
  transform: translateX(0);
  transition: 0.3s;
  z-index: 999;
}
.chat.off,
.goTop.off {
  opacity: 0;
  transition: 0.3s;
  transform: translateX(100%);
}
.chat.absolute {
  bottom: 9.2em;
}
.goTop.absolute {
  bottom: 5.7em;
}

/* 20.09.23 slider btn ë³€ê²½ */
.slick-prev,
.slick-next {
  height: 30px;
}
.slick-prev:before,
.slick-next:before {
  display: block;
  height: 30px;
}
.slick-prev:before {
  /* content: url(/src/assets/img/common/btn_left.png) !important; */
}
.slick-next:before {
  /* content: url(/src/assets/img/common/btn_right.png) !important; */
}
