.boardCg {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > li {
    font-size: 0.7em;
    padding: 0.15em 0.3em;
    margin: 0.15em 0.5em 0.15em 0;

    &.active {
      //   background-color: #222530;
      //   color: white;
      border-bottom: 2px solid red;
      //   border-radius: 0.5em;
    }
  }
}
