/* common */
body,
th,
td,
input,
select,
textarea,
button {
  font-size: 24px;
}

@media screen and (max-width: 755px) {
  body.bootpay-open
    .bootpay-payment-background
    > .bootpay-payment-window
    > button.bootpay-payment-close {
    display: block !important;
  }
}

section {
  position: relative;
}

.header .logo .clogo,
.header .ab_right .c_ico {
  display: none;
}

.section1 {
  z-index: 1;
}

.topBannerArea {
  position: relative;
}

.bannerDetail {
  height: 30em;
}

.section2 .bannerDetail {
  position: relative;
  margin: 1em auto 0;
  width: 100%;
  max-width: 1200px;
  height: 280px;
}
@media (max-width: 768px) {
  .section2 .bannerDetail {
    height: auto;
  }
}
/* slick always has same link solution ----start*/
.section2 .bannerDetail .slick-slide {
  visibility: hidden;
}
.section2 .bannerDetail .slick-slide.slick-active {
  visibility: visible;
}
/* slick always has same link solution ----end*/

.listArea {
  position: relative;
  left: 50%;
  padding: 0 0.25em;
  width: 100%;
  max-width: 1200px;
  transform: translateX(-50%);
}
.section1 .listArea {
  position: absolute;
  bottom: -3em;
}

.sectionTit {
  position: relative;
  margin: 0 auto;
  padding: 0 0.25em;
  width: 100%;
  max-width: 1200px;
  color: #fff;
  font-size: 1em;
  font-weight: 500;
}

.listArea .goContest {
  position: absolute;
  top: 0;
  right: 0.35em;
  padding: 0 1em;
  color: #fff;
  font-size: 0.75em;
  font-weight: 300;
  line-height: calc(3.2em - 2px);
  border: 1px solid rgba(255, 255, 255, 0.7);
  z-index: 1;
}
.listArea .goContest b {
  color: #ffd200;
  font-size: 1.35em;
  font-weight: 400;
}
.sectionTit span {
  position: relative;
  display: inline-block;
}
.sectionTit span:after {
  content: "";
  position: absolute;
  bottom: 0.2em;
  right: -0.6em;
  width: 0.3em;
  height: 0.3em;
  border-radius: 50%;
  background-color: #e9360c;
}
.sectionTit small {
  display: block;
}
.sectionTit small {
  font-size: 0.67em;
  font-weight: 400;
}
.sectionTit .mt_btn {
  position: absolute;
  display: table;
  right: 0;
  bottom: 0;
  padding: 0.25em 1em;
  font-size: 0.67em;
  font-weight: 400;
  color: #fff;
  border: 1px solid #fff;
}

.detailList {
  position: relative;
  width: 100%;
  height: 30em;
  text-align: center;
  overflow: hidden;
}
.section1 .detailList > img.detailImg {
  position: absolute;
  left: 50%;
  max-width: 2000px;
  transform: translateX(-50%);
}

.section1 .detailList .info_area {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0 0.25em;
  width: 100%;
  max-width: 1200px;
  text-align: left;
  z-index: 1;
  transform: translate(-50%, -65%);
}
.detailList .info_area > span {
  display: block;
  color: #fff;
  font-size: 0.66em;
  font-weight: 400;
  text-align: left;
  text-shadow: 0.08em 0.08em 0.25em rgba(0, 0, 0, 0.25);
}
.detailList.wbg .info_area > span {
  color: #323232;
  text-shadow: none;
}
.detailList .info_area > span.info_title {
  width: 35%;
  font-size: 1.25em;
  font-weight: 600;
}
.detailList .info_area > span.info_title small {
  display: block;
  font-size: 0.5em;
  font-weight: 400;
}
.detailList .info_area > span.info_date {
  margin: 1.5em 0 1em;
}
.detailList .info_area > span.info_date b {
  display: inline-block;
  margin-left: 1em;
  padding: 0.1em 0.4em;
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.detailList.wbg .info_area > span.info_date b {
  border: 1px solid rgba(50, 50, 50, 0.7);
}
.detailList .info_area > a {
  display: inline-block;
  margin-top: 1.5em;
  padding: 0.25em 3.5em;
  color: #fff;
  font-size: 0.66em;
  border: 1px solid #fff;
  text-shadow: 0.08em 0.08em 0.25em rgba(0, 0, 0, 0.25);
  box-shadow: 0.08em 0.08em 0.25em rgba(0, 0, 0, 0.15);
}
.detailList.wbg .info_area > a {
  color: #323232;
  border: 1px solid #323232;
  text-shadow: none;
  box-shadow: none;
}

.section2 .detailList {
  position: relative;
  display: flex !important;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 280px;
  text-align: center;
  overflow: hidden;
  background-color: #2b2f3b;
}
@media (max-width: 768px) {
  .section2 .detailList {
    height: auto;
    flex-direction: column-reverse;
  }
}

.section2 .detailList .info_area {
  position: relative;
  padding: 1em 0.5em;
  width: calc(100% - 880px);
}

.section2 .detailList .info_area .info_title {
  display: block;
  width: 100%;
  font-size: 1em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.3em;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 3.9em;
}
.section2 .detailList .info_area > span.info_date {
  margin: 0.6em auto;
  font-size: 0.6em;
  letter-spacing: 0em;
}
.section2 .detailList .info_area > span.info_list1,
.section2 .detailList .info_area > span.info_list2 {
  font-size: 0.6em;
}
.section2 .detailList .info_area > a {
  position: absolute;
  bottom: 1.5em;
  left: 1em;
  width: calc(100% - 2em);
  box-sizing: border-box;
}
.section2 .detailList img.detailImg {
  width: 880px;
}

.bannerList {
  margin-top: 1em;
  padding: 0.75em 0 0.25em;
  width: 100%;
  overflow: hidden;
}
.section2 .specialA .bannerList {
  padding: 0.25em 0 0.75em;
}
.specialA .bannerList .slick-list .slick-track .slick-slide {
  margin: 5px 10px 15px 10px;
}
.bannerList .slick-list {
  position: relative;
  overflow: unset;
  left: calc(50% - 18.5em);
  padding: 0 !important;
  width: 1200px;
  transform: translateX(-12%);
}
.specialA .bannerList .slick-list {
  overflow: unset;
  position: relative;
  /* left: calc(50% - 38.9em); */
  /* left: calc(50% - 18.7em); */
  left: 13%;
  /* left: 0 !important; */
  /* padding: 0 !important; */
  width: 1200px;
  transform: translateX(-12%);
}
@media (max-width: 1024px) {
  .bannerList .slick-list {
    left: unset;
    padding: 0 !important;
    width: 1200px;
    transform: unset;
  }
  .specialA .bannerList .slick-list {
    left: unset;
    /* left: 0 !important; */
    /* padding: 0 !important; */

    transform: unset;
  }
}

@media (max-width: 768px) {
  .specialA .bannerList .slick-list {
    padding-left: 10px !important;
    left: unset;
    transform: unset;
  }
}
.topBannerArea .bannerList .slick-list {
  /* padding-left: 0.3em !important; */
  left: 13%;
}
.topBannerArea.mo .bannerList .slick-list {
  left: unset;
  padding-left: 0.3em !important;
}
@media (min-width: 376px) and (max-width: 425px) {
  .bannerList .slick-list {
    position: relative;
    left: calc(50% - 2.25em);
    padding: 0 !important;
    width: 1200px;
    transform: translateX(-12%);
  }
  .topBannerArea.mo .bannerList .slick-list {
    left: unset;
    padding-left: 0.3em !important;
    transform: unset;
  }
}
@media (max-width: 375px) {
  .topBannerArea.mo .bannerList .slick-list {
    left: unset;
    padding-left: 0.3em !important;
    transform: unset;
  }
  .bannerList .slick-list {
    position: relative;
    left: unset !important;
    padding: 0.3em !important;
    width: 1200px;
    /* transform: translateX(-12%); */
  }
}

.specialB .bannerList .slick-list {
  left: 12%;
}
@media (max-width: 1024px) {
  .specialB .bannerList .slick-list {
    left: unset;
  }
}
@media (max-width: 768px) {
  .specialB .bannerList .slick-list {
    left: unset;
  }
}

@media (max-width: 426px) {
  .specialB .bannerList .slick-list {
    left: calc(50% - 2.25em);
  }
}

.slick-slider .slick-track {
  margin-left: 0;
}
.slick-dots li.slick-active button:before,
.slick-dots li button:before {
  color: white;
}

@media screen and (max-width: 425px) {
  .customEvent .slick-slider .slick-track {
    margin-left: auto;
    margin-right: auto;
  }
}

.customRecom .slick-list .slick-track {
  /* width: auto !important; */
}
.bannerList .slick-list .slick-track {
  margin: 0 auto;
  margin-left: 0;
  width: auto;
}
.topBannerArea .bannerList .slick-list .slick-track {
  margin: 0 auto;
  margin-left: 0;
  /* width: auto !important; */
}
.specialA .bannerList .slick-list .slick-track {
  margin: 0 auto;
  margin-left: 0;
}

.specialB .bannerList .slick-list .slick-track {
  margin: 0 auto;
  margin-left: 0;
}
.bannerList .slick-list .slick-track .slick-cloned {
  /* display: none; */
}
.bannerList .slick-list .slick-track .slick-slide {
  position: relative;
  margin: 15px 10px 5px 10px;
  width: 280px !important;
  height: 6.25em;
  box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.2);
}
.bannerList.mobile .slick-list .slick-track .slick-slide {
  position: relative;
  margin: 15px 10px 5px 10px;
  width: 200px !important;
  height: 107px;
  box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.2);
}
.bannerList.mo .slick-list .slick-track .slick-slide {
  position: relative;
  margin: 15px 10px 5px 10px;
  width: 200px !important;
  height: 107px;
  box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.2);
}
.specialB .bannerList .slick-list .slick-track .slick-slide {
  position: relative;
  margin: 15px 10px 5px 10px;
  width: 280px;
  height: 6.25em;
  box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.2);
}
/* .bannerList .slick-list .slick-track .slick-slide.slick-center, */
.section2
  .specialA
  .bannerList
  .slick-list
  .slick-track
  .slick-slide.slick-current,
.topBannerArea .bannerList .slick-list .slick-track .slick-slide.slick-current {
  outline: 5px solid #fff !important;
}
.section2
  .specialA
  .bannerList
  .slick-list
  .slick-track
  .slick-slide.slick-current:before,
.topBannerArea
  .bannerList
  .slick-list
  .slick-track
  .slick-slide.slick-current:before {
  content: "";
  position: absolute;
  top: -15px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  transform: translateX(-50%);
}

.bannerList .slick-list .slick-track .slick-slide .thumbList {
  position: relative;
}
.bannerList .slick-list .slick-track .slick-slide .thumbList .th_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: 0.3s;
}
.bannerList
  .slick-list
  .slick-track
  .slick-slide.slick-center
  .thumbList
  .th_overlay,
.bannerList .slick-list .slick-track .slick-slide:hover .thumbList .th_overlay {
  opacity: 0;
  transition: 0.3s;
}

.bannerList .slick-list .slick-track .slick-slide .info_area {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  top: 0;
  left: 0;
  padding: 0.5em;
  height: 6.25em;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1;
  opacity: 0;
  transition: 0.3s;
}
.bannerList .slick-list .slick-track .slick-slide:hover .info_area {
  opacity: 1;
}
.bannerList .slick-list .slick-track .slick-slide .info_area > span {
  display: block;
  color: #fff;
}
.bannerList .slick-list .slick-track .slick-slide .info_area span.info_title {
  width: 100%;
  font-size: 0.75em;
  line-height: 1.2em;
}
.customLiAfter::before {
  content: "";
}

.bannerList
  .slick-list
  .slick-track
  .slick-slide
  .info_area
  span.info_title
  small {
  display: block;
}
.bannerList .slick-list .slick-track .slick-slide .info_area span.info_list1,
.bannerList .slick-list .slick-track .slick-slide .info_area span.info_date {
  position: absolute;
  bottom: 1em;
  width: 50%;
  font-size: 0.55em;
}
.bannerList .slick-list .slick-track .slick-slide .info_area span.info_list1 {
  left: 1em;
}
.bannerList .slick-list .slick-track .slick-slide .info_area span.info_date {
  right: 1em;
  text-align: right;
}
.bannerList .slick-list .slick-track .slick-slide .info_area span.info_date b {
  display: inline-block;
  margin-left: 1em;
  padding: 0.15em 0.4em;
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.bannerList
  .slick-list
  .slick-track
  .slick-slide
  .info_area
  span.info_date
  span.condition {
  padding: 0.25em 0.5em;
  width: 4em;
  color: #fff;
  text-align: center;
}
.bannerList
  .slick-list
  .slick-track
  .slick-slide
  .info_area
  span.info_date
  span.condition.ing {
  background-color: #0dbf76;
}
.bannerList
  .slick-list
  .slick-track
  .slick-slide
  .info_area
  span.info_date
  span.condition.deadline {
  background-color: #ff7200;
}
.bannerList
  .slick-list
  .slick-track
  .slick-slide
  .info_area
  span.info_date
  span.condition.end {
  color: #393939;
  background-color: #fff;
}

.section2
  .specialA
  .bannerList
  .slick-list
  .slick-track
  .slick-slide.slick-current:before {
  top: auto;
  bottom: -15px;
  border-top: 10px solid #fff;
  border-bottom: none;
}
.section2 {
  position: relative;
  padding: 5em 0 4em;
  background: rgb(62, 54, 86); /* Old browsers */
  background: -moz-linear-gradient(
    45deg,
    rgba(62, 54, 86, 1) 0%,
    rgba(84, 76, 110, 1) 80%,
    rgba(84, 76, 110, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    45deg,
    rgba(62, 54, 86, 1) 0%,
    rgba(84, 76, 110, 1) 80%,
    rgba(84, 76, 110, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    45deg,
    rgba(62, 54, 86, 1) 0%,
    rgba(84, 76, 110, 1) 80%,
    rgba(84, 76, 110, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e3656', endColorstr='#544c6e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.bannerList.bl3 .slick-list .slick-track .slick-slide.slick-center {
  outline: none;
}
.bannerList.bl3
  .slick-list
  .slick-track
  .slick-slide.slick-active
  .thumbList
  .th_overlay {
  opacity: 0;
}
.bannerList.bl3 .slick-list .slick-track .slick-slide.slick-center:before {
  display: none;
}

.section2 .specialB .listArea .sectionTit {
  margin-top: 2em;
}
.section2 .specialB .bannerList .slick-list .slick-track .slick-slide {
  height: auto;
  box-shadow: none;
}

.section2
  .specialB
  .bannerList
  .slick-list
  .slick-track
  .slick-slide
  .thumbList
  > a
  > .info_area {
  opacity: 0;
}
.section2
  .specialB
  .bannerList
  .slick-list
  .slick-track
  .slick-slide
  .thumbList:hover
  > a
  > .info_area {
  opacity: 1;
  border-inline: 5px solid #fff;
  border-bottom: 5px solid #fff;
  border-top: 5px solid #fff;
  /* outline: 5px solid #fff; */
}
.section2
  .specialB
  .bannerList.mo
  .slick-list
  .slick-track
  .slick-slide.slick-current
  .thumbList
  > a
  > .info_area {
  outline: 5px solid #fff;
  opacity: 1;
}
.section2 .specialB .bannerList .slick-list .slick-track .slick-slide > div {
  height: 150px;
  box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.2);
}
.section2 .specialB .bannerList.mo .slick-list .slick-track .slick-slide > div {
  height: 107px;
  box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.2);
}
.section2
  .specialB
  .bannerList
  .slick-list
  .slick-track
  .slick-slide
  > div:first-child {
  margin-bottom: 1em;
}

.section2 .specialB .slick-slide .thumbList .info_area {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  top: 0;
  left: 0;
  padding: 0.5em;
  height: 150px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1;
  opacity: 0;
  transition: 0.3s;
}
.section2 .specialB .slick-slide .thumbList:hover .info_area {
  opacity: 1;
}
.section2 .specialB .slick-slide .thumbList .info_area > span {
  display: block;
  color: #fff;
}
.section2 .specialB .slick-slide .thumbList .info_area span.info_title {
  width: 100%;
  font-size: 0.75em;
  line-height: 1.2em;
}
.section2 .specialB .slick-slide .thumbList .info_area.mo span.info_title {
  font-size: 0.55em;
}
.section2 .specialB .slick-slide .thumbList .info_area span.info_title small {
  display: block;
}
.section2 .specialB .slick-slide .thumbList .info_area span.info_list1,
.section2 .specialB .slick-slide .thumbList .info_area span.info_date {
  position: absolute;
  bottom: 1em;
  width: 50%;
  font-size: 0.55em;
}
.section2 .specialB .slick-slide .thumbList .info_area span.info_list1 {
  left: 1em;
}
.section2 .specialB .slick-slide .thumbList .info_area span.info_date {
  right: 1em;
  text-align: right;
}
.section2 .specialB .slick-slide .thumbList .info_area span.info_date b {
  display: inline-block;
  margin-left: 1em;
  padding: 0.15em 0.4em;
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.section2
  .specialB
  .slick-slide
  .thumbList
  .info_area
  span.info_date
  span.condition {
  padding: 0.25em 0.5em;
  width: 4em;
  color: #fff;
  text-align: center;
}
.section2
  .specialB
  .slick-slide
  .thumbList
  .info_area
  span.info_date
  span.condition.ing {
  background-color: #0dbf76;
}
.section2
  .specialB
  .slick-slide
  .thumbList
  .info_area
  span.info_date
  span.condition.deadline {
  background-color: #ff7200;
}
.section2
  .specialB
  .slick-slide
  .thumbList
  .info_area
  span.info_date
  span.condition.end {
  color: #393939;
  background-color: #fff;
}

.section3 {
  position: relative;
  top: -1.5em;
  margin-bottom: -3em;
}
.section3 .midBanner {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: 100%;
  max-width: 1188px;
  overflow-x: hidden;
}

.section4 {
  padding: 3.5em 0 2em;
}
.section4 .bannerList .slick-list {
  left: 50%;
}
.section4 .bannerList .slick-list .slick-track .slick-slide {
  margin: 0;
  padding: 0 0.25em;
  width: 100vw;
  max-width: 1200px;
  height: auto;
  box-shadow: none;
}
.section4 .bannerList .slick-list .slick-track .slick-slide.slick-center {
  outline: none;
}
.section4 .bannerList .slick-list .slick-track .slick-slide.slick-center:before,
.section4 .bannerList .slick-list .slick-track .slick-slide.slick-center:after {
  display: none;
}

.section4 .bannerList .slick-list .thumbList {
  display: block !important;
  margin-bottom: 0.5em;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.section4 .bannerList .slick-list .thumbList a {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
}
.section4 .bannerList .slick-list .thumbList .info_area {
  margin-left: 1em;
  padding: 0.5em 0.5em 0.5em 0;
  width: calc(100% - 13em);
}
.section4 .bannerList .slick-list .thumbList .info_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.section4 .bannerList .slick-list .thumbList .info_area span {
  display: block;
  width: 5em;
  color: #fff;
  font-size: 0.9em;
  line-height: 1.2em;
}
.section4 .bannerList .slick-list .thumbList .info_area .info_title {
  margin-bottom: 0.5em;
  width: 100%;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.section4 .bannerList .slick-list .thumbList .info_area .info_title small {
  display: block;
  font-size: 0.75em;
  font-weight: 300;
}
.section4 .bannerList .slick-list .thumbList .info_area .info_list1,
.section4 .bannerList .slick-list .thumbList .info_area .info_date {
  width: 50%;
  font-size: 0.675em;
  font-weight: 300;
}
.section4 .bannerList .slick-list .thumbList .info_area .info_date {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.section4
  .bannerList
  .slick-list
  .thumbList
  .info_area
  .info_date
  span.condition {
  padding: 0.25em 0.5em;
  width: 5em;
  color: #fff;
  text-align: center;
}
.section4
  .bannerList
  .slick-list
  .thumbList
  .info_area
  .info_date
  span.condition.ing {
  background-color: #0d82bf;
}
.section4
  .bannerList
  .slick-list
  .thumbList
  .info_area
  .info_date
  span.condition.deadline {
  background-color: #ff3c00;
}
.section4
  .bannerList
  .slick-list
  .thumbList
  .info_area
  .info_date
  span.condition.end {
  color: #393939;
  background-color: #fff;
}
.section4 .bannerList .slick-list .thumbList .info_area .info_date b {
  display: inline-block;
  margin-left: 1em;
  padding: 0.15em 0.4em;
  border: 1px solid rgba(255, 255, 255, 0.7);
}

.section5 {
  position: relative;
  padding: 0;
}

.more {
  position: absolute;
  right: 0.25em;
  top: 50%;
  color: rgba(255, 255, 255, 0.5);
}

.section5 .interview_list {
  margin: 0.75em auto 2.5em;
  padding: 0 0.25em;
  width: 100%;
  max-width: 1200px;
}
.section5 .interview_list ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.section5 .interview_list ul li {
  margin-right: 1em;
  width: 380px;
}
.section5 .interview_list ul li:last-child {
  margin-right: 0;
}

.section5 .interview_list ul li a .text_area {
  padding: 1em;
  color: #fff;
  background-color: #3f4453;
}
.section5 .interview_list ul li a .text_area span.list_title {
  display: block;
  font-size: 0.83em;
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 2.4em;
}

.section5 .interview_list ul li a .text_area p.list_date {
  margin-top: 1.5em;
  font-size: 0.67em;
  font-weight: 300;
}

.client {
  position: relative;
  margin: 0 auto 2em;
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
}
.client .slick-list {
  position: relative;
  padding: 0 !important;
  width: 100%;
}
.client .slick-list .slick-track {
  margin: 0 auto;
}
.client .slick-list .slick-track .slick-slide {
  margin: 0 0.5em;
  width: calc((1188px - 5em) / 6);
}
.client .slick-list .slick-track .slick-slide a img {
  margin: 0 auto;
}

/* 20.05.27 ìµœì‹ ê³µëª¨ì „ ì¶”ê°€ */
.section4.new {
  margin-bottom: -1em;
  padding-bottom: 0;
}
.section4 .banner_list.new {
  position: relative;
  margin: 0 auto;
  padding: 0 0.25em;
  width: 100%;
  max-width: 1200px;
}
.section4 .banner_list.new ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1.75em;
}
.section4 .banner_list.new ul.mobile {
  flex-wrap: unset;
}
.section4 .banner_list.new ul li {
  margin: 0 0.5em 0.5em 0;
  width: calc((100% - 2em) / 5);
}
.section4 .banner_list.new ul li:nth-child(5n) {
  margin-right: 0;
}
.section4 .banner_list.new ul li a {
  position: relative;
  display: block;
}
.section4 .banner_list.new ul li a > img {
  width: 228px;
  height: 326px;
}
.section4 .banner_list.new ul li a .info_area {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  padding: 2em 0.5em;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1;
  opacity: 0;
  transition: 0.3s;
}
.section4 .banner_list.new ul li:hover a .info_area {
  opacity: 1;
}
.section4 .banner_list.new ul li a .info_area > span {
  display: block;
  color: #fff;
}
.section4 .banner_list.new ul li a .info_area span.info_title {
  margin-bottom: 0.75em;
  width: 100%;
  font-size: 0.75em;
  line-height: 1.2em;
}
.section4 .banner_list.new ul li a .info_area span.info_title small {
  display: block;
}
.section4 .banner_list.new ul li a .info_area span.info_list1,
.section4 .banner_list.new ul li a .info_area span.info_date {
  width: 100%;
  font-size: 0.55em;
}
.section4 .banner_list.new ul li a .info_area span.info_list1 {
  left: 1em;
}
.section4 .banner_list.new ul li a .info_area span.info_date {
  position: absolute;
  bottom: 4em;
  left: 1em;
}
.section4 .banner_list.new ul li a .info_area span.info_date b {
  display: inline-block;
  margin-left: 1em;
  padding: 0.15em 0.4em;
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.section4 .banner_list.new ul li a .info_area span.info_date span.condition {
  padding: 0.25em 0.5em;
  width: 4em;
  color: #fff;
  text-align: center;
}
.section4
  .banner_list.new
  ul
  li
  a
  .info_area
  span.info_date
  span.condition.ing {
  background-color: #0dbf76;
}
.section4
  .banner_list.new
  ul
  li
  a
  .info_area
  span.info_date
  span.condition.deadline {
  background-color: #ff7200;
}
.section4
  .banner_list.new
  ul
  li
  a
  .info_area
  span.info_date
  span.condition.end {
  color: #393939;
  background-color: #fff;
}
