@import "./media.scss";

$f-width: 100%;
$f-height: 100%;

$bg-modal: rgba(0, 0, 0, 0.5);
$bg-modal-content: rgba(255, 255, 255, 1);
$modal-border: 1px solid #b2b2b2;
//key color
$key-color: #263050;

.customModalWrap {
	position: fixed;
	left: 0;
	top: 0;
	width: $f-width;
	height: $f-height;
	background-color: $bg-modal;
	z-index: 0;
	transition: all 0.2s;
	opacity: 0;
	display: none;
	&.active {
		z-index: 100;
		opacity: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.customModalBody {
		width: calc($f-width/2);
		// height: calc($f-height/2);
		height: auto;
		background-color: $bg-modal-content;
		// transform: translate(50%, 50%);
		z-index: 3;
		@include bp1024 {
			width: calc($f-width/2);
			&.participate {
				width: 100%;
			}

			// height: auto;
			// transform:translate(50%,50%);
		}

		@include bp768 {
			width: 100%;
			.th.row {
				display: none;
			}
			.td.row {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				.regNum {
					display: none;
				}
				.regContent {
					border: none;
					> ul {
						&.userInfo {
							&.applyList {
								> li {
									> span {
										display: none;
									}
								}
							}
						}
					}
				}
				.regPeriod {
					width: 100%;
					justify-content: end;
					color: rgba(0, 0, 0, 0.5);
				}
			}
		}
		@include bp425 {
			width: 100%;
			.th.row {
				display: none;
			}
			.td.row {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				.regNum {
					display: none;
				}
				.regContent {
					border: none;
					> ul {
						&.userInfo {
							&.applyList {
								> li {
									> span {
										display: none;
									}
								}
							}
						}
					}
				}
				.regPeriod {
					width: 100%;
					justify-content: end;
					color: rgba(0, 0, 0, 0.5);
				}
			}
		}

		> div.modalContentBody {
			padding: 0 1.5em;
			margin-top: 0.5em;
			@include bp1024 {
				&.participate {
					width: 100%;
				}
			}

			&.address {
				padding: 0;
			}

			> ul {
				border: $modal-border;
				background-color: #fafafa;

				&.contentUl {
					max-height: 356px;
					overflow-y: overlay;
					border-left: 0;
					border-right: 0;
					border-bottom: 0;
					padding: 0 0.1em;
					margin: 0 0.1em;

					> li:last-child {
						border-bottom: 0;
					}
				}

				> li {
					padding: 0.5em;
					border-bottom: $modal-border;

					> div.contentWrap {
						display: flex;
						justify-content: flex-start;
						flex-wrap: wrap;
						flex-direction: column;
						font-size: 0.75em;

						> label {
							overflow-x: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							position: relative;
							display: block;
							margin-right: 1%;
							padding-left: 1em;
							padding-right: 4%;
							width: 100% !important;
							font-size: 0.9em;
							// border-right: 1px solid #b2b2b2;
							@include bp1024 {
								width: 100% !important;
								border-right: none;
							}

							&:after {
								position: absolute;
								content: "※";
								top: 0;
								left: 0.5em;
								color: red;
								font-size: 0.5em;
							}
						}
						> input[type="text"],
						> input[type="password"] {
							padding: 0.1em;
							border: none;
							width: 80%;
							background: none;
							&::placeholder {
								font-size: 0.6em;
							}
							@include bp768 {
								width: 100%;
							}
							@include bp425 {
								width: 100%;
							}
						}

						> .contentSummary {
							width: 100%;
							display: flex;
							justify-content: space-between;
							font-size: 0.8em;
							padding: 0 0.6em;
							@include bp425 {
								flex-direction: column;
							}
						}
					}
				}
			}

			> .searchBtn {
				display: block;
				text-align: center;
				width: 100%;
				border: none;
				background: $key-color;
				color: white;
				margin-top: 0.6em;
				margin-bottom: 0.6em;
				padding: 0.3em;
				font-size: 0.8em;

				a {
					color: white;
				}
			}
		}
	}
}
