/* common */
body,
th,
td,
input,
select,
textarea,
button {
  font-size: 24px;
}

.moveTopBtnArea {
  position: fixed;
  z-index: 11111;
  bottom: 1em;
  right: 2em;
}
.moveTopBtnArea button {
  padding: 1em;
  border-radius: 1em;
  border: none;
}
.moveTopBtnArea svg {
  cursor: pointer;
}

.customSelect {
  padding: 5px 1em;
}
.dropdownElement {
  /* padding: 0.25em 0; */
  margin-bottom: 0.25em;

  /* border-radius: 5px; */
  padding: 0.5em 1em;
  width: 100%;
  color: #263050;
  cursor: pointer;
}
.dropdownElement:last-child {
  margin-bottom: 0;
}
.dropdownElement:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.dropdownElement > span {
  /* font-size: 0.8em; */
}
.drop-enter {
  position: absolute;
  overflow: hidden;
  opacity: 0;
  transform: translate(0%, 0);
  margin-top: 10px;
  border-radius: 5px;
  background-color: white;
  color: black;

  z-index: 1;
  right: 0.9em;
}
.drop-enter-active {
  margin-top: 10px;
  border-radius: 5px;
  background-color: white;
  color: black;

  z-index: 1;
  right: 0.9em;

  position: absolute;
  overflow: hidden;
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
  transform: translate(0%);
}
.drop-enter-done {
  margin-top: 10px;
  border-radius: 5px;
  background-color: white;
  color: black;

  z-index: 1;
  right: 0.9em;

  position: absolute;
  overflow: hidden;
}
.drop-exit {
  margin-top: 10px;
  border-radius: 5px;
  background-color: white;
  color: black;

  z-index: 1;
  right: 0.9em;

  position: absolute;
  overflow: hidden;
  opacity: 1;
}
.drop-exit-active {
  position: absolute;
  overflow: hidden;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.sub_container {
  background-color: #faf9f9;
  overflow: hidden;
}

.sub_container .contents {
  position: relative;
  margin: 0 auto;
  padding: 3em 6px;
  width: 100%;
  max-width: 1200px;
}
.sub_container .contents.products {
  max-width: 1032px;
}
.sub_container.search .contents {
  border-bottom: 1px solid #969696;
}
.sub_container.search .contents:last-child {
  border: none;
}

.contents > .sub_title {
  position: relative;
  display: block;
  color: #282c36;
  font-size: 1.5em;
  line-height: 1.8em;
  border-bottom: 1px solid #565656;
}
.contents.mypage > .sub_title a.btn {
  position: absolute;
  display: block;
  top: 50%;
  right: 0;
  padding: 0.25em 1em 0.35em;
  font-size: 0.45em;
  font-weight: 400;
  line-height: 1.5em;
  color: #fff;
  background-color: #263050;
  transform: translateY(-50%);
}
.contents > .sub_title small {
  display: block;
  margin-bottom: 1em;
  font-size: 0.5em;
  line-height: 1.2em;
}
/* sub visual */
.subVisual {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3.5em;
  width: 100%;
  height: 14.5em;
}

.subVisual.contestTop {
  /* background: url(/subVisual_contest.png) no-repeat top center; */
  background-size: auto;
}

.subVisual .sv_title {
  color: #fff;
  font-size: 2em;
  font-weight: 500;
}

.subVisual .sub_menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 0.25em;
  width: 100%;
  max-width: 1200px;
}
.subVisual .sub_menu li {
  margin: 0.25em 0.5em 0.25em 0;
  width: calc((100% - 1.5em) / 4);
}
.subVisual.hostcenter .sub_menu li {
  width: calc((100% - 1.5em) / 5);
}
.subVisual .sub_menu li:nth-child(4n),
.subVisual .sub_menu li:last-child {
  margin-right: 0;
}
.subVisual.hostcenter .sub_menu li:nth-child(4n) {
  margin-right: 0.5em;
}
.subVisual .sub_menu li a {
  display: block;
  padding: 0.5em;
  color: #fff;
  font-size: 0.667em;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: 0.3s;
}
.subVisual .sub_menu li:hover a {
  background-color: rgba(255, 255, 255, 0.15);
  transition: 0.3s;
}
.subVisual .sub_menu li.active a {
  color: #323232;
  background-color: #fff;
  border-color: #fff;
}
.subVisual .sub_menu li a span {
  position: relative;
}
.subVisual .sub_menu li a span:after {
  position: absolute;
  content: "";
  right: -2em;
  bottom: 0.15em;
  width: 0.35em;
  height: 0.35em;
  opacity: 0;
  border-radius: 1em;
  background-color: #e9360c;
  transition: 0.3s;
}
.subVisual .sub_menu li:hover a span:after,
.subVisual .sub_menu li.active a span:after {
  right: -0.65em;
  opacity: 1;
  transition: 0.3s;
}

/* contest page */

.recommend_area {
  position: relative;
  padding: 0;
  height: 28em;
  background: rgb(62, 54, 86); /* Old browsers */
  background: -moz-linear-gradient(
    45deg,
    rgba(62, 54, 86, 1) 0%,
    rgba(84, 76, 110, 1) 80%,
    rgba(84, 76, 110, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    45deg,
    rgba(62, 54, 86, 1) 0%,
    rgba(84, 76, 110, 1) 80%,
    rgba(84, 76, 110, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    45deg,
    rgba(62, 54, 86, 1) 0%,
    rgba(84, 76, 110, 1) 80%,
    rgba(84, 76, 110, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e3656', endColorstr='#544c6e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  overflow: hidden;
}
.sectionTit {
  position: relative;
  margin: 1.5em auto 0.5em;
  padding: 0 0.25em;
  width: 100%;
  max-width: 1200px;
  color: #fff;
  font-size: 1em;
  font-weight: 500;
}
.sectionTit span {
  position: relative;
  display: inline-block;
  font-weight: 600;
}
.sectionTit span:after {
  content: "";
  position: absolute;
  bottom: 0.2em;
  right: -0.6em;
  width: 0.3em;
  height: 0.3em;
  border-radius: 50%;
  background-color: #e9360c;
}
.sectionTit small {
  display: block;
}

.sectionTit .more {
  position: absolute;
  right: 0.25em;
  color: rgba(0, 0, 0, 0.5);
}

.contest_list.recommend {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 0 0.25em 1.5em;
  width: 100%;
  max-width: 1200px;
}
.contest_list.recommend .slick-list {
  padding: 0 !important;
  overflow: hidden;
}
@media screen and (max-width: 425px) {
  .contest_list.recommend .slick-list {
    padding: 0px 50px !important;
    overflow: hidden;
  }
}
.contest_list.recommend .slick-slide {
  margin-right: 0.7em;
  width: calc((100% - 2.895em) / 4);
  max-width: 280px;
}
/* @media screen and (max-width: 768px) {
	.contest_list.recommend .slick-slide {
		/* margin-right: 0.7em; */
/* width: calc((100% - 2.895em) / 4); */
/* width: 280px !important; */
/* max-width: 280px; */
/* } */
/* } */
.contest_list.recommend .slick-slide .thumbList a > img {
  width: 280px;
  height: 400px;
}
.contest_list.recommend .slick-slide .list_text span,
.contest_list.recommend .slick-slide .list_text p {
  display: block;
  color: #fff;
  text-align: center;
  line-height: 1em;
}
.contest_list.recommend .slick-slide .list_text span.contest_name {
  margin: 0.75em 0 0.5em;
  /* display: -webkit-box; */
  display: block;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.3em;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 2.6em;
}

/* 210422 맞춤검색 */
.custom_search {
  position: relative;
  background: #fff;
}
.custom_search .cs_wrap {
  position: relative;
  margin: 0 auto;
  padding: 1.5em 6px;
  width: 100%;
  max-width: 1200px;
}
.custom_search .cs_wrap .sectionTit {
  margin: 0 0 1em 0;
  color: #282c36;
}
.custom_search .cs_wrap .cs_filter li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5em;
}
.custom_search .cs_wrap .cs_filter li > span {
  display: table;
  width: 8em;
  font-size: 0.75em;
}
.custom_search .cs_wrap .cs_filter li .input_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: calc(100% - 6em);
}
.custom_search .cs_wrap .cs_filter li .input_area input {
  display: none;
}
.custom_search .cs_wrap .cs_filter li .input_area label {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5em;
  padding: 0.25em;
  width: calc((100% - 3.5em) / 8);
  font-size: 0.6em;
  text-align: center;
  /*border:1px solid #cfcfcf;*/
}
.custom_search .cs_wrap .cs_filter li .input_area label {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5em;
  padding: 0.25em;
  width: calc((100% - 3.5em) / 8);
  font-size: 0.6em;
  text-align: center;
  /*border:1px solid #cfcfcf;*/
}
.custom_search .cs_wrap .cs_filter li .input_area label.active {
  color: #fff;
  background-color: #263050;
}
.custom_search .cs_wrap .cs_filter li .input_area label:last-child {
  margin-right: 0;
}
.custom_search .cs_wrap .cs_filter li .input_area input:checked + label {
  color: #fff;
  background-color: #263050;
  /*border-color:#263050;*/
}

/** edit jiwan start **/
.board_search {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2em 0;
  padding: 1em 0;
  background-color: #fff;
  border: 1px solid #cfcfcf;
}
.board_search select,
.board_search input,
.board_search button {
  display: table;
  padding: 0.25em 0.5em;
  font-size: 0.75em;
  line-height: 1.4em;
}
.board_search button {
  padding: 0.25em 1em;
  color: #fff;
  background-color: #263050;
  border: 1px solid #263050;
}
.board_search input {
  margin: 0 0.5em;
}
label.radioAll {
  padding: 0 0.5em;
  cursor: pointer;
}
label.radioAll.active {
  background: #473f5f;
  color: white;
}

.filter_area {
  margin-top: 2em;
  display: flex;
  justify-content: flex-end;
}
.filter_area label,
.filter_area select {
  font-size: 0.65em;
}
.filter_area label {
  margin-right: 0.5em;
  padding: 0.25em 1em;
  border: 1px solid #969696;
}
.filter_area label.active {
  color: #fff;
  background-color: #263050;
  border-color: #263050;
}

.filterRadio {
  display: none;
}

/** edit jiwan end **/
.contest_list.recommend .slick-slide .list_text p.contest_period {
  font-size: 0.75em;
}
.contest_list.recommend .slick-arrow {
  transform: translateY(-250%);
}
.contest_list.recommend .slick-next {
  right: -30px;
}
.contest_list.recommend .slick-prev {
  left: -30px;
}

.contents.contest_area .sectionTit {
  margin-top: 0;
  color: #282c36;
}

/* sub index */
h2.index_title {
  margin: 2em auto 1em;
  text-align: center;
}
h2.index_title span {
  display: inline-block;
  padding-bottom: 0.2em;
  border-bottom: 5px solid #e9360c;
}

/* contest view */
.contents.contest_area .contest_view .contest_detail.top {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.contents.contest_area .contest_view .contest_detail.top img.contest_detail {
  margin-right: 2em;
  width: 14.58333333333333em;
}
.contents.contest_area .contest_view .contest_detail.top .contestTop_text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 16.58333333333333em);
}
.contents.contest_area .contest_view .contestTop_text .ct_top {
  position: relative;
  padding: 1.25em 0;
  width: 100%;
  border-top: 1px solid #7f7f7f;
  border-bottom: 1px solid #7f7f7f;
}
.contents.contest_area .contest_view .contestTop_text .ct_top > span {
  display: block;
}
.contents.contest_area
  .contest_view
  .contestTop_text
  .ct_top
  span.contest_name {
  width: calc(100% - 5.5em);
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.2em;
}
.contents.contest_area
  .contest_view
  .contestTop_text
  .ct_top
  span.contest_host {
  margin-bottom: 1em;
  font-size: 0.75em;
}
.contents.contest_area .contest_view .contestTop_text .ct_top a.btn {
  display: inline-block;
  padding: 0.5em 1.5em;
  line-height: 0.6em;
  border: 1px solid #4b4b4b;
}
.contents.contest_area .contest_view .contestTop_text .ct_top a.btn span {
  line-height: 1em;
  font-size: 0.75em;
}
.contents.contest_area .contest_view .contestTop_text .ct_top a.btn.go_contest {
  color: #fff;
  background-color: #4285f4;
  border-color: #4285f4;
}
.contents.contest_area .contest_view .contestTop_text .ct_top a.btn.go_applyFor,
.contents.contest_area .contest_view .contestTop_text .ct_top a.btn.go_teamplay,
.contents.contest_area .contest_view .contestTop_text .ct_top a.btn.go_qna {
  margin-left: 0.25em;
  float: right;
  color: #fff;
  background-color: #3f4453;
}
.contents.contest_area .contest_view .contestTop_text .ct_top .sns_ico {
  position: absolute;
  top: 1.25em;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contents.contest_area .contest_view .contestTop_text .ct_top .sns_ico a {
  margin-left: 1em;
}

.contents.contest_area .contest_view .contestTop_text .ct_bottom {
  width: 100%;
}

.contents.contest_area .contest_view .contestTop_text .ct_bottom .ctTop_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}
.contents.contest_area
  .contest_view
  .contestTop_text
  .ct_bottom
  .ctTop_list
  li {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0.5em;
  padding: 0.5em 0;
  width: calc((100% - 0.5em) / 2);
  border-bottom: 1px solid #909297;
}
.contents.contest_area
  .contest_view
  .contestTop_text
  .ct_bottom
  .ctTop_list
  li:nth-child(2n) {
  margin-right: 0;
}
.contents.contest_area
  .contest_view
  .contestTop_text
  .ct_bottom
  .ctTop_list
  li
  span.ctTop_title {
  width: 5.5em;
  font-size: 0.75em;
  color: #4285f4;
}
.contents.contest_area
  .contest_view
  .contestTop_text
  .ct_bottom
  .ctTop_list
  li
  > a {
  width: calc(100% - 4em);
}
.contents.contest_area
  .contest_view
  .contestTop_text
  .ct_bottom
  .ctTop_list
  li
  p.ctTop_text {
  font-size: 0.67em;
  line-height: 1.5em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 3em;
  word-break: break-all;
}
.contents.contest_area
  .contest_view
  .contestTop_text
  .ct_bottom
  .ctTop_list
  li
  > p.ctTop_text {
  width: calc(100% - 6.5em);
}
.contents.contest_area
  .contest_view
  .contestTop_text
  .ct_bottom
  .ctTop_list
  li
  span.dDay,
.contents.contest_area
  .contest_view
  .contestTop_text
  .ct_bottom
  .ctTop_list
  li
  a
  p {
  padding: 0 0.5em;
  color: #fff;
  background-color: #4285f4;
}
.contents.contest_area
  .contest_view
  .contestTop_text
  .ct_bottom
  .ctTop_list
  li
  span.dDay {
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 0.67em;
  transform: translateY(-50%);
}
.contents.contest_area
  .contest_view
  .contestTop_text
  .ct_bottom
  .ctTop_list
  li
  span.dDay.end {
  background-color: #969696;
}

.contest_detail.middle {
  margin-top: 1em;
  border-bottom: 1px solid rgba(15, 15, 15, 0.2);
}
.contest_detail.middle > ul > li {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1.75em 0;
}
.contest_detail.middle > ul > li:first-child {
  padding-top: 3.5em;
}
.contest_detail.middle > ul > li:last-child {
  padding-bottom: 3.5em;
}
.contest_detail.middle > ul > li:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  top: 0;
  left: 14.58333333333333em;
  background-color: rgba(15, 15, 15, 0.2);
}
.contest_detail.middle > ul > li .cd_title {
  margin-right: 0.5em;
  padding-right: 0.5em;
  width: 14.58333333333333em;
}
.contest_detail.middle > ul > li .cd_title span {
  position: relative;
  display: block;
  width: 100%;
  font-size: 1.25em;
  font-weight: 600;
  text-align: right;
}
.contest_detail.middle > ul > li .cd_title span:after {
  position: absolute;
  content: "";
  width: 0.25em;
  height: 0.25em;
  right: -0.44em;
  bottom: 0.25em;
  border-radius: 1em;
  background-color: #e9360c;
}
.contest_detail.middle > ul > li .cd_text {
  padding: 1.3em 0 0 0.5em;
  width: calc(100% - 15.08333333333333em);
}
.contest_detail.middle > ul > li .cd_text > ul,
.contest_detail.middle > ul > li .cd_text > ol {
  padding-left: 1em;
}

.contest_detail.middle > ul > li .cd_text ul li,
.contest_detail.middle > ul > li .cd_text ol li {
  list-style: unset;
}
.contest_detail.middle > ul > li .cd_text li,
.contest_detail.middle > ul > li .cd_text p {
  font-size: 0.75em;
}

.contest_detail.comment {
  padding-bottom: 1em;
  border-bottom: 1px solid rgba(15, 15, 15, 0.2);
}
.contest_detail.comment ul li {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 1.25em 0;
  border-bottom: 1px solid rgba(15, 15, 15, 0.2);
}
.contest_detail.comment ul li:last-child {
  border: none;
}
.contest_detail.comment ul li span.cmt_name {
  margin-right: 1em;
}
.contest_detail.comment ul li p.cmt_date {
  font-size: 0.75em;
  color: rgba(0, 0, 0, 0.5);
}
.contest_detail.comment ul li p.cmt_text {
  margin-top: 0.5em;
  width: 100%;
  font-size: 0.75em;
}
.contest_detail.comment .comment_write {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1.25em;
  background-color: rgba(0, 0, 0, 0.075);
}
.contest_detail.comment .comment_write .replyTit {
  display: block;
  margin-bottom: 0.5em;
  width: 100%;
}
.contest_detail.comment .comment_write textarea {
  width: calc(100% - 7em);
  border: none;
}
.contest_detail.comment .comment_write button.btn {
  margin-left: 1em;
  width: 6em;
  color: #fff;
  background-color: #303649;
  border: none;
}

.contents .contest_view .btn_area {
  justify-content: flex-end;
  padding: 1.25em 0;
}
.contents .contest_view .btn_area a.btn,
.contents .contest_view .btn_area button.btn {
  padding: 0.5em 2em;
  font-size: 0.75em;
  background-color: #3f4453;
}

/* channel */
.sub_container.channel section {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3em 0;
}
.sub_container.channel section.boardList {
  margin: 0 auto;
  width: 1188px;
}
.sub_container.channel section .sectionTit {
  margin: 0 0 1em;
  padding: 0 0 1em;
  color: #282c36;
  border-bottom: 1px solid #afaeae;
}
.sub_container.channel section .sectionTit .more {
  position: absolute;
  right: 0;
  top: 50%;
  color: rgba(0, 0, 0, 0.5);
  transform: translateY(-35%);
}
.sub_container.channel section.event .idx_event,
.sub_container.channel section.event .idx_event {
  margin-right: 1em;
  width: calc((1188px - 1em) / 2);
}
.sub_container.channel.event section.event .idx_event {
  margin-right: 0;
  width: 1188px;
}
.sub_container.channel section.event .idx_event.host {
  margin-right: 0;
}

/* thumbnail list layout */
.thumbList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.thumbList.slider {
  overflow-x: hidden;
}
.sub_container.channel section.event .idx_event .thumbList li {
  margin-right: 0.9155em;
  width: calc((100% - 0.9155em) / 2);
}
.sub_container.channel section.event .idx_event .thumbList.event_list li {
  margin-right: 0.9155em;
  width: calc((100% - 2.7465em) / 4);
}
.sub_container.channel
  section.event
  .idx_event
  .thumbList.event_list
  li:nth-child(4n),
.sub_container.channel section.event .idx_event .thumbList li:last-child {
  margin-right: 0;
}
.thumbList li a .thumb_title {
  display: block;
  margin-top: 0.5em;
  font-size: 0.75em;
  line-height: 1.2em;
  height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.thumbList.depic li a .thumb_title {
  display: block;
  margin-top: 0.5em;
  font-size: 0.85em;
  line-height: 1.2em;
  height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.thumbList li a .thumb_date {
  margin-top: 0.5em;
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.75em;
  text-align: right;
}
.board_list.gallery .thumbList li a .thumb_date,
.board_list.video .thumbList li a .thumb_date {
  text-align: left;
}

.sub_container.channel section.interview,
.sub_container.channel section.list {
  background-color: rgba(0, 0, 0, 0.035);
}
.sub_container.channel section.interview:last-child {
  background-color: rgba(0, 0, 0, 0);
}
.sub_container.channel.event section.list {
  background: none;
}
.sub_container.channel section.interview .idx_interview {
  width: 1188px;
}
.sub_container.channel section.interview .idx_interview.ch_index {
  margin: 0 auto;
}
.sub_container.channel section.interview .idx_interview.host {
  margin-bottom: 2em;
}
.sub_container.channel section.interview .idx_interview .thumbList li {
  margin-right: 0.7em;
  width: auto;
  /* width: calc((100% - 3em) / 4); */
}
.sub_container.channel
  section.interview
  .idx_interview
  .thumbList
  li:nth-child(4n) {
  margin-right: 0;
}
.sub_container.channel section.interview .idx_interview .thumbList li {
  background-color: #3f4453;
}
.sub_container.channel section.interview .idx_interview .thumbList li a span {
  padding: 0 0.5em;
  color: #fff;
}
.sub_container.channel section.interview .idx_interview .thumbList li a img {
  width: 100%;
}
.sub_container.channel section.interview .idx_interview .thumbList li a p {
  padding: 0 0.5675em 0.5675em;
  color: rgba(255, 255, 255, 0.7);
}

/* board list layout */
.boardList li {
  padding: 0.25em 0;
}
.boardList li a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.75em;
}
.boardList li a span,
.boardList li a p {
  display: block;
}
.boardList li a span.brd_category,
.boardList li a p.brd_date {
  font-size: 0.75em;
}
.sub_container.channel section.boardList .idx_board {
  width: calc((1188px - 1em) / 2);
}
.sub_container.channel
  section.boardList.challenge
  .idx_board.freeboard:first-child {
  margin-right: 1em;
}
.sub_container.channel section.boardList .idx_interview.host {
  width: calc((1188px - 2em) / 2);
}

.sub_container.channel .boardItem:first-child {
  margin-right: 1em;
}
.sub_container.channel section.boardList .idx_board.weekly,
.sub_container.channel section.boardList .idx_board.qna {
  /* margin-right: 1em; */
}
.sub_container.channel section.boardList .idx_board.weekly,
.sub_container.channel section.boardList .idx_board.teamplay {
  margin-bottom: 3em;
}

.sub_container.channel section.boardList .idx_interview.host ul li {
  margin-right: 1em;
  width: calc((100% - 1em) / 2);
  padding-top: 0;
}
.sub_container.channel
  section.boardList
  .idx_interview.host
  ul
  li:nth-child(2n) {
  margin-right: 0;
}
.sub_container.channel section.boardList .idx_interview.host ul li a {
  display: block;
}

.sub_container.channel section.boardList .idx_board .boardList {
  padding: 0.5em 1em;
  background-color: rgba(0, 0, 0, 0.035);
}

.sub_container.channel
  section.boardList
  .idx_board
  .boardList
  li
  a
  span.brd_category,
.sub_container.channel section.boardList .idx_board .boardList li a p.brd_date {
  width: 7em;
}
.sub_container.channel section.boardList .idx_board .boardList li a p.brd_date {
  text-align: right;
}
.sub_container.channel
  section.boardList
  .idx_board
  .boardList
  li
  a
  span.brd_title {
  margin: 0 0.5em;
  width: calc(100% - 11.5em);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sub_container.channel
  section.boardList
  .idx_board.freeboard
  .boardList
  li
  a
  span.brd_title {
  margin-left: 0;
  width: calc(100% - 5.7em);
}

/* event index */
section.list .event_list {
  margin: 0 auto;
  width: 1188px;
}
section.list .event_list .list_detizen {
  margin-bottom: 3em;
}
section.list .event_list ul.thumbList li {
  margin: 0 1em 1em 0;
  width: calc((100% - 2em) / 3);
}
section.list .event_list ul.thumbList.newThumb li {
  width: calc((100% - 3em) / 4);
}
section.list .event_list ul.thumbList li:nth-child(3n) {
  margin-right: 0;
}
section.list .event_list ul.thumbList.newThumb li:nth-child(3n) {
  margin-right: 1em;
}
section.list .event_list ul.thumbList.newThumb li:nth-child(4n) {
  margin-right: 0;
}
section.list .event_list ul.thumbList li a img {
  width: 100%;
  max-width: 380px;
}

/* inverview list */
.interview_list ul li {
  margin-bottom: 1em;
}
.interview_list + .paging_area {
  margin-top: 1em;
}

/* mypage */
.myInfoTop {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  padding: 1em 0;
  border-bottom: 1px solid #565656;
}
.myInfoTop .infoArea {
  margin: 0;
  width: 24.25em;
}
.myInfoTop .infoArea ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5em;
  padding: 0.25em 0;
  border-bottom: 1px solid #b6b5b5;
  font-size: 0.8em;
}
.myInfoTop .infoArea ul li:last-child {
  margin-bottom: 0;
}
.myInfoTop .infoArea ul li span.mit_tit {
  display: block;
  margin-right: 0.5em;
  width: 7em;
  font-weight: 500;
}
.myInfoTop .infoArea ul li p.mit_text {
  display: block;
  width: calc(100% - 7.5em);
  font-weight: 400;
}

.myInfoTop .infoArea .btn_area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1em;
}

.myInfoTop .infoArea .btn_area > a.btn {
  margin-left: 0;
  margin-right: 1em;
  padding: 0.5em;
  width: calc(50% - 0.5em);
  color: #fff;
  font-size: 0.75em;
  text-align: center;
  background-color: #263050;
}
.myInfoTop .infoArea .btn_area > a.btn:last-child {
  margin-right: 0;
}

.myPageList ul li {
  position: relative;
  padding: 0.7em 0 0 1.5em;
  font-size: 0.75em;
}
.myPageList ul li:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0.45em;
  width: 2px;
  height: 100%;
  background-color: rgba(121, 122, 125, 0.3);
}
.myPageList ul li.top:before {
  top: auto;
  bottom: 0;
  height: 50%;
}
.myPageList ul li.bottom:before {
  height: 50%;
}
.myPageList ul li:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 0.8em;
  height: 0.8em;
  border: 2px solid #797a7d;
  background-color: #fff;
  border-radius: 50%;
  transform: translateY(-50%);
}
.myPageList ul li.top:after {
  border-color: #597ff5;
}
.myPageList ul li.empty {
  padding-left: 0;
}
.myPageList ul li.empty:before,
.myPageList ul li.empty:after {
  display: none;
}
.myPageList ul li a {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 0.75em;
  border-bottom: 1px solid #969696;
}
.myPageList ul li a span.scrap_title {
  display: block;
  margin-bottom: 0.5em;
  width: calc(100% - 5em);
  font-size: 1em;
  line-height: 1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.myPageList ul li a p {
  color: #646464;
  font-size: 0.8em;
  font-weight: 400;
  line-height: 1em;
}
.myPageList ul li a p.scrap_company {
  width: calc(100% - 6.25em);
}
.myPageList ul li a p.scrap_date {
  position: absolute;
  top: calc(50% - 0.375em);
  right: 0;
  transform: translateY(-50%);
}

.myContest {
  width: 100%;
  padding: 1em;
}
.myContest > ul {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}
.myContest > ul > li {
  margin-right: 0.833333em;
  width: calc((100% - 2.5em) / 4);
  background-color: #fff;
}
.myContest > ul > li:last-child {
  margin-right: 0;
}
ul > li.empty {
  width: 100%;
  background: none;
}
.myContest > ul > li.empty {
  margin: 5em auto;
  width: 100%;
}
ul > li.empty > img {
  margin: 0 auto;
}
.myContest > ul > li > a {
}
.myContest > ul > li > a > img {
  margin-right: 0.5em;
  width: 100%;
}
.myContest > ul > li > a > .text_area {
  padding: 0.5em;
  width: 100%;
}
.myContest > ul > li > a > .text_area span.contest_title {
  display: block;
  margin-bottom: 0.5em;
  font-size: 0.8em;
  font-weight: 500;
  line-height: 1.2em;
}
.myContest > ul > li > a > .text_area .contest_company {
  font-size: 0.65em;
}
.myContest > ul > li > a > .text_area .contest_period {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.65em;
}
.myContest > ul > li > a > .text_area .contest_period .dDay b {
  color: #0dbf76;
}

.myInfoMid {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 1em 0;
}
.myInfoMid .scrap {
  margin-bottom: 1em;
  padding: 1em;
  width: 100%;
  height: 24.5em;
  background-color: #f3f3f3;
}

.myInfoMid .event {
  padding: 1.5em 1em;
  width: 100%;
  height: 13em;
  background-color: #f3f3f3;
}
.myInfoMid .buy {
  padding: 1.5em 1em;
  width: calc((100% - 1em) / 2);
  height: 13em;
  background-color: #f3f3f3;
}
.myInfoMid .participate {
  margin-bottom: 1em;
  padding: 1.5em 1em;
  background-color: #f3f3f3;
}
.myInfoMid .participate:last-child {
  margin-bottom: 0;
}
.myInfo_area h3 {
  position: relative;
  margin: 0 auto 0.5em;
  font-size: 1em;
}
.myInfo_area h3 .goRegist {
  position: absolute;
  right: 2em;
  top: 50%;
  padding: 0.1em 1em;
  font-size: 0.67em;
  font-weight: 500;
  color: #fff;
  background-color: #263050;
  transform: translateY(-50%);
  border-radius: 50px;
}

.myInfo_area h3 .more {
  position: absolute;
  right: 0;
  top: 50%;
  color: rgba(0, 0, 0, 0.5);
  transform: translateY(-50%);
}
.myInfoMid .participate ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  width: 45em;
}
.myInfoMid .participate ul li {
  margin-right: 2em;
  width: calc((100% - 4em) / 3);
  font-size: 0.75em;
}
.myInfoMid .participate ul li:nth-child(3n) {
  margin-right: 0;
}
.myInfoMid .participate ul li a {
  display: block;
}
.myInfoMid .participate ul li a img {
  width: 100%;
  height: auto;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.025);
}
.myInfoMid .participate ul li span,
.myInfoMid .participate ul li p {
  display: block;
}
.myInfoMid .participate ul li span {
  margin: 0.5em 0 0.25em;
  font-weight: 600;
}
.myInfoMid .participate ul li p {
  font-size: 0.8em;
}
.myInfoMid .participate ul li p.scrap_date {
  text-align: right;
}

.myInfoBot {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.myInfoBot .myPageList {
  position: relative;
  margin-right: 1em;
  width: calc((100% - 1em) / 2);
  height: 13.5em;
  padding: 1.5em 1em;
  background-color: #f3f3f3;
}
.myInfoBot .myPageList.comment {
  margin-right: 0;
}
.myInfoBot .myPageList ul {
  position: relative;
}
.myInfoBot .myPageList ul li a {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 0.75em;
  border-bottom: 1px solid #969696;
}
.myInfoBot .myPageList ul li a span.write_title {
  display: block;
  margin-bottom: 0.5em;
  width: calc(100% - 5em);
  font-size: 1em;
  line-height: 1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.myInfoBot .myPageList ul li a p {
  color: #646464;
  font-size: 0.8em;
  font-weight: 400;
  line-height: 1em;
}
.myInfoBot .myPageList ul li a p.write_board {
  width: calc(100% - 6.25em);
}
.myInfoBot .myPageList ul li a p.write_user {
  width: calc(100% - 6.25em);
}
.myInfoBot .myPageList ul li a p.write_date {
  position: absolute;
  top: calc(50% - 0.375em);
  right: 0;
  transform: translateY(-50%);
}

/* mypage 李몄뿬�� �대깽�� */
.myInfo_event {
}
.myInfo_event.buyList {
  border-top: 2px solid #969696;
}
.myInfo_event > ul > li {
  position: relative;
  padding: 1em;
  border-bottom: 1px solid #969696;
}
.myInfo_event.buyList > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}
.myInfo_event.buyList > ul > li {
  position: relative;
  width: 50%;
  border-right: 1px solid #969696;
  border-bottom: 1px solid #969696;
}
.myInfo_event.buyList > ul > li:nth-child(2n) {
  border-right: none;
}
.myInfo_event.buyList > ul > li a.btn.inquiry {
  position: absolute;
  display: block;
  top: 1em;
  right: 1em;
  z-index: 1;
}
.myInfo_event.buyList > ul > li a.btn.inquiry span {
  display: block;
  padding: 0.25em 1em;
  color: #fff;
  background-color: #263050;
  font-size: 0.65em;
}
.myInfo_event > ul > li > a .event_Info {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.myInfo_event.buyList > ul > li > a .event_Info {
  display: block;
}
.myInfo_event > ul > li > a .event_Info img {
  margin-right: 1em;
  width: 6.25em;
}
/*.myInfo_event > ul > li > a .event_Info .textArea {
	position:relative;
	margin-right:1em;
	width:calc((100% - 11.167em) / 2);
}*/
.myInfo_event > ul > li > a .event_Info .textArea {
  position: relative;
  width: calc((100% - 10.167em));
}
.myInfo_event.buyList > ul > li > a .event_Info .textArea {
  width: 100%;
  margin-bottom: 1em;
  font-size: 1.1em;
}
.myInfo_event.buyList > ul > li > a .event_Info .costArea {
  width: 100%;
  padding: 0.5em;
  border: 1px solid #969696;
}
.myInfo_event.buyList > ul > li > a .event_Info .costArea span.costTit {
  display: block;
  margin-bottom: 0.5em;
  padding-bottom: 0.25em;
  font-size: 1em;
  border-bottom: 1px solid #969696;
}
.myInfo_event.buyList > ul > li > a .event_Info .costArea ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.75em;
}
.myInfo_event.buyList > ul > li > a .event_Info .costArea ul li.total {
  margin-top: 0.5em;
  padding-top: 0.25em;
  font-size: 0.85em;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.myInfo_event > ul > li > a .event_Info .textArea span.event_name {
  width: 100%;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.myInfo_event > ul > li > a .event_Info .textArea p {
  font-size: 0.65em;
}
.myInfo_event > ul > li > a .event_Info .textArea p.event_period {
  margin-top: 1em;
}
.myInfo_event > ul > li > a .event_Info .textArea p.event_period span.dDay b {
  margin-left: 1em;
  padding: 0 0.4em;
  color: #587ef5;
  border: 1px solid #587ef5;
}
.myInfo_event
  > ul
  > li
  > a
  .event_Info
  .textArea
  p.event_period
  span.dDay.end
  b {
  color: #969696;
  border: 1px solid #969696;
}
.myInfo_event > ul > li > a .event_Info .textArea p.event_period,
.myInfo_event > ul > li > a .event_Info .textArea p.event_pDate {
  color: #323232;
}

/*.myInfo_event > ul > li > a .event_Info .event_inputItem {
	position:relative;
	padding:1em;
	width:calc((100% - 11.167em) / 2);
	border:1px solid #969696;
}*/
.myInfo_event > ul > li > a .event_Info .event_inputItem {
  position: relative;
  margin-top: 1em;
  padding: 0.5em;
  width: 100%;
  border: 1px solid #969696;
}
.myInfo_event > ul > li > a .event_Info .event_inputItem > ul > li {
  margin-bottom: 0.5em;
  font-size: 0.65em;
}
.myInfo_event > ul > li > a .event_Info .event_inputItem > ul > li:last-child {
  margin-bottom: 0;
}
.myInfo_event
  > ul
  > li
  > a
  .event_Info
  .event_inputItem
  > ul
  > li
  span.item_title {
  position: relative;
  margin-bottom: 0.5em;
  padding-left: 0.5em;
  font-size: 1.1em;
  font-weight: 500;
}
.myInfo_event
  > ul
  > li
  > a
  .event_Info
  .event_inputItem
  > ul
  > li
  span.item_title:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 0.25em;
  height: 0.25em;
  background-color: #587ef5;
  transform: translateY(-50%);
}
.myInfo_event > ul > li > a .event_Info .event_inputItem > ul > li p.item_text {
  padding-left: 0.55em;
  font-size: 1em;
}

/* board layout */
.board_list {
  position: relative;
}
.board_list ul,
.board_view ul {
  border-top: 3px solid #565656;
}
.board_list.gallery ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}
.board_list ul li,
.board_view ul li {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em 0;
  font-size: 0.75em;
  border-bottom: 1px solid #dcdcdc;
}

.board_list.gallery ul li {
  margin: 0 1em 1em 0;
  width: calc((100% - 3em) / 4);
  border: none;
}
.board_list.video ul li {
  margin: 0 1em 1em 0;
  width: calc((100% - 2em) / 3);
  border: none;
}

.board_list.video.depic ul li {
  width: calc((100% - 3em) / 4);
}
@media screen and (max-width: 755px) {
  .sub_container.channel.customEvent .customEvent_sub {
  }
  .sub_container.channel.customEvent .customEvent_sub > small {
    display: flex;
    align-items: flex-start !important;
    flex-direction: column;
  }
  .sub_container.channel.customEvent .customEvent_sub > small > div:last-child {
    margin-top: 1em;
  }
  .sub_container.channel.customEvent .customEvent_sub > small > div > a {
    margin-top: 1em;
  }
  .board_list.video.depic ul li {
    width: 100%;
  }
  .board_list.video.depic ul li > a {
    text-align: center;
  }
  .board_list.video.depic ul li > a > img {
    display: unset;
  }
  section.list .event_list ul.thumbList li {
    margin: unset;
    width: 100%;
    padding: 0.25em 0.5em;
  }
  section.list .event_list ul.thumbList li > a {
    text-align: center;
  }
  section.list .event_list ul.thumbList li > a > img {
    display: unset;
  }
}
.board_list.gallery ul li a,
.board_list.video ul li a {
  display: block;
}
.board_list.video ul li a img {
  width: 100%;
}
.board_list.gallery ul li:nth-child(4n),
.board_list.video ul li:nth-child(3n) {
  margin-right: 0;
}
.board_list.video.depic ul li:nth-child(3n) {
  margin-right: 1em;
}
.board_list.video.depic ul li:nth-child(4n) {
  margin-right: 0em;
}
.board_list.teamplay ul li.td,
.board_list.faq ul li.td {
  display: block;
}

.board_list.faq ul li.td ul.faq_answer {
  display: none;
  margin-top: 0.5em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.board_list.faq ul li.td ul.faq_answer li {
  padding: 1em;
  border: none;
  font-size: 0.85em;
}

.board_list ul li.th,
.board_view ul li.th,
.board_list ul li:last-child,
.board_view ul li:last-child {
  border-color: #565656;
}
.board_list ul li > a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.board_list ul li > a.go_contest {
  display: inline-block;
  position: relative;
  margin-bottom: 0.25em;
  padding: 0.25em 1em;
  width: auto;
  color: #fff;
  font-size: 0.75em;
  background-color: #4285f4;
  border-radius: 1em;
}
.board_list ul li > span > a.go_contest {
  display: inline-block;
  position: relative;
  margin-bottom: 0.25em;
  padding: 0.25em 1em;
  width: auto;
  color: #fff;
  font-size: 0.75em;
  background-color: #4285f4;
  border-radius: 1em;
}
.board_list ul li > span > a.notice {
  display: inline-block;
  position: relative;
  margin-bottom: 0.25em;
  padding: 0.25em 1em;
  width: auto;
  color: #fff;
  font-size: 0.75em;
  background-color: #1e242f;
  border-radius: 1em;
}
.board_list ul li span {
  display: block;
  text-align: center;
}
.board_list.gallery ul li span,
.board_list.video ul li span {
  text-align: left;
}
.board_list ul li span.brd_cate {
  margin: 0 2.5%;
  padding: 0.2em 0;
  color: #fff;
  font-size: 0.8em;
  background-color: #323232;
}
.board_list ul li span.brd_cate.focus {
  background-color: #4285f4;
}
.board_list ul li span.brd_title {
  position: relative;
  padding-left: 1.75em;
  text-align: left;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.board_list ul li span.brd_title img.lock {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.board_list ul li span.brd_answer.standby {
  color: #e35a1d;
}

.board_view ul li {
  display: block;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.board_view ul li.th {
  padding: 1em 0;
}
.board_view ul li.td {
  padding: 1em;
}
.board_view ul li.td.view.answer span.answer_title {
  position: relative;
  display: inline-block;
  margin-bottom: 0.5em;
  font-size: 1.25em;
  font-weight: 600;
}
.board_view ul li.td.view.answer span.answer_title:after {
  content: "";
  position: absolute;
  bottom: 0.2em;
  right: -0.5em;
  width: 0.25em;
  height: 0.25em;
  border-radius: 50%;
  background-color: #e9360c;
}

.board_view.event ul li.td.form {
  font-size: 0.9em;
  background-color: rgba(0, 0, 0, 0.035);
}
.board_view.event ul li.td.form .form_title {
  display: block;
  margin-bottom: 0.5em;
  width: 100%;
}
.board_view.event ul li.td.form .form_table .input_area {
  border-left: 1px solid #969696;
}
.board_view.event ul li.td.form .customChk > label {
  font-size: 1em;
}
.board_view.event ul li.td.form .customChk > p.form_description {
  font-size: 0.9em;
}
.board_view.event ul li .event_input {
  width: 100%;
}
.board_view.event ul li .event_input ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1em;
}
.board_view.event ul li .event_input ul li .input_area {
  padding: 0;
}
.board_view.event ul li .event_input ul li .input_area label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.board_view.event ul li .event_input .btn_area {
  margin-top: 1em;
}
.board_view.event
  ul
  li
  .event_input
  ul
  li
  .input_area
  label
  input[type="radio"] {
  display: block;
  margin-right: 0.5em;
  width: 1em;
  height: 1em;
}
.board_view.event ul li .event_input ul li .input_area label span {
}

.board_view ul li.th span.category {
  margin-bottom: 0.5em;
  padding: 0.25em 0.5em;
  color: #fff;
}
.board_view ul li.th span.category.focus,
.board_view ul li.th span.category.contest {
  background-color: #4285f4;
}
.board_view ul li.th span.category.weekly {
  background-color: #323232;
}
.board_view ul li.th span.qna_category {
  margin-bottom: 0.5em;
  padding: 0.25em 0.5em;
  color: #fff;
  background-color: #646464;
}
.board_view ul li.th span.board_title {
  width: 100%;
  font-size: 1.4em;
  font-weight: 600;
}
.board_view ul li.th p {
  display: block;
  margin: 1em 1em 0 0;
  padding-right: 1em;
  line-height: 1em;
  border-right: 1px solid #969696;
}
.board_view ul li.th p#board_regDate {
  width: 100%;
}
.board_view ul li.th p:last-child {
  border-right: none;
}
.board_view ul li.th p.board_status {
  text-align: center;
}
.board_view ul li.th p.board_status.standby {
  color: #e35a1d;
}

.board_view .answer_area {
  margin-top: 1em;
}
.board_view .answer_area ul {
  border-top: 1px solid #565656;
  background-color: rgba(255, 255, 255, 0.75);
}
.answer_area ul li {
  position: relative;
  padding: 1em;
}
.answer_area ul li.answer .cmt_btn {
  position: absolute;
  top: 1em;
  right: 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.answer_area ul li.answer .cmt_btn a.btn {
  margin-left: 0.5em;
  padding: 0.125em 0.5em;
  font-size: 0.75em;
  border: 1px solid #969696;
  cursor: pointer;
}
.answer_area ul li.answer span.answer_name {
  margin-right: 1em;
  font-size: 1.1em;
}
.answer_area ul li.answer p {
  font-size: 0.9em;
}
.answer_area ul li.answer p.answer_date {
  color: #969696;
  font-size: 0.8em;
}
.answer_area ul li.answer p.answer_text {
  margin-top: 0.5em;
  width: 100%;
}
.answer_area ul li.answer.depth02 {
  padding-left: 2.25em;
}
.answer_area ul li.answer.depth02:before {
  position: absolute;
  content: "��";
  top: 1em;
  left: 0.65em;
}
.answer_area ul li.answer.depth02 p.answer_text span.answer_by {
  margin-right: 0.5em;
  color: #969696;
  font-size: 0.9em;
}

.answer_area .answer_input {
  padding: 1em;
  font-size: 0.75em;
  border-bottom: 1px solid #565656;
}
.answer_area .answer_input .input_area,
.answer_area .input_area.answer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}
.answer_area .answer_input .input_area .hidden_area {
  margin-bottom: 0.5em;
  width: 100%;
}
.answer_area .answer_input .input_area textarea,
.answer_area .input_area.answer textarea {
  margin-right: 1em;
  width: calc(100% - 9em);
  border: 1px solid #dcdcdc;
}
.answer_area.comment .answer_input .input_area textarea {
  width: calc(100% - 10em);
}
.answer_area .answer_input .input_area button,
.answer_area .input_area.answer button {
  width: 5em;
  color: #fff;
  background-color: #646464;
  border: none;
}
.answer_area .answer_input .input_area button span,
.answer_area .input_area.answer button span {
  font-size: 0.75em;
  line-height: 1em;
}

.board_view ul li .input_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
  width: 100%;
  border-bottom: 1px solid #565656;
}
.board_view ul li .input_area.answer {
  padding: 1em 0 0 0;
}
.board_view.event ul.form_table li .input_area {
  flex-wrap: wrap;
  padding-top: 0;
}
.board_view ul li .input_area:first-child {
  padding-top: 0;
}
.board_view ul li .input_area:last-child {
  padding-bottom: 0;
  border: none;
}
.board_view ul li .input_area input[type="text"],
.board_view ul li .input_area input[type="file"],
.board_view ul li .input_area select {
  font-size: 1em;
}

.search_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 1em 0;
}
.search_area .search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #565656;
}
.search_area .search input,
.search_area .search select,
.search_area .search button {
  background: none;
  border: none;
}
.search_area .search input {
  width: calc(100% - 2em);
  font-size: 0.95em;
}
.search_area .search button {
  width: 2em;
  box-sizing: border-box;
}

/* contest list layout */
.contest > ul {
  padding: 0.5em 0;
}
.contest > ul > li,
.form-group > ul > li {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5em;
  padding: 0.75em 1em;
  background-color: #fff;
  border: 1px solid #cfcfcf;
}
.contest > ul > li.recomm {
  border-color: #263050;
}
.contest > ul > li.recomm:before {
  position: absolute;
  content: "추천";
  left: -3.8em;
  top: -1px;
  padding: 0.25em 1em;
  width: auto;
  color: #fff;
  font-size: 0.6em;
  background-color: #263050;
  border-radius: 5px 0 0 5px;
}
.contest > ul > li .contestList_info {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1em;
  width: 35em;
}
.contest > ul > li .contestList_info a.contest_name {
  position: relative;
  display: block;
  margin-bottom: 0.5em;
  width: 100%;
  font-weight: 500;
  line-height: 1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.contest > ul > li a.contest_delete {
  position: absolute;
  top: 0.75em;
  right: 0.5em;
  font-size: 0.65em;
  line-height: 1em;
  transform: translateY(-50%);
}
.contest > ul > li .contestList_info > span {
  width: auto;
  margin-right: 1.5em;
  padding-right: 1.5em;
  font-size: 0.65em;
  line-height: 1em;
  border-right: 1px solid #969696;
  /* flex: 1; */
}
.contest > ul > li .contestList_info > span.contest_cate {
  color: #4bb9ff;
  white-space: nowrap;
}
.contest > ul > li .contestList_info > span.contest_view {
  border-right: none;
  flex: 6;
}
.contest > ul > li .contestList_info > span.contest_view img {
  display: inline-block;
}
.contest > ul > li .contestList_date {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 14em;
}
.contest > ul > li .contestList_date > span {
  font-size: 0.65em;
}
.contest > ul > li .contestList_date > span.contest_dDay {
  margin-left: 1em;
  padding: 0.1em 0;
  width: 5em;
  color: #fff;
  text-align: center;
}
.contest > ul > li .contestList_date > span.contest_dDay.ing {
  background-color: #0dbf76;
}
.contest > ul > li .contestList_date > span.contest_dDay.deadline {
  background-color: #ff7200;
}
.contest > ul > li .contestList_date > span.contest_dDay.end {
  background-color: #969696;
}

/* 二쇱턀�� �쒕퉬�� */
/* main header */
.header.hc {
  position: relative;
  margin: 0 auto;
  width: 100%;
  background-color: #fafafa;
  border-bottom: 1px solid #afaeae;
  z-index: 100;
}
.header.hc .logo .wlogo,
.header.hc .ab_right .w_ico {
  display: none;
}
.header.hc .logo .clogo,
.header.hc .ab_right .c_ico {
  display: block;
}
.header.hc .main_nav ul li a {
  color: #323232;
}
.wrap.hc.open .header.hc .logo .wlogo {
  display: block;
}
.wrap.hc.open .header.hc .logo .clogo {
  display: none;
}

.wrap.hc .subVisual {
  flex-wrap: wrap;
  padding-top: 0;
  background-color: #fafafa;
}
.wrap .subVisual .sv_title {
  display: block;
  width: 100%;
  color: #fff;
  font-size: 2.5em;
  text-align: center;
  letter-spacing: -0.05em;
  word-spacing: 0.05em;
}
.wrap.hc .subVisual .sv_title {
  color: #444343;
}

.wrap .subVisual .sv_description {
  display: block;
  color: #fff;
  font-size: 0.5em;
}
.wrap.hc .subVisual .sv_description {
  color: #444343;
}
.wrap .subVisual .sv_guide {
  display: block;
  font-size: 0.3em;
  letter-spacing: -0.03em;
}
.wrap.hc .subVisual .sv_guide {
  color: #c86355;
}
.wrap.hc .sub_container {
  background-color: #f5f5f5;
}
.wrap.hc .sub_container.white {
  background-color: #fff;
}

.wrap.hc .container > .benefit {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 12.5em;
  background-color: #f4d370;
}

.wrap.hc .container > .benefit .slogan {
  width: 100%;
  font-size: 2.1em;
  line-height: 1.2em;
  text-align: center;
}
.wrap.hc .container > .benefit .slogan p {
  display: inline-block;
  margin: 0 15%;
  padding: 0.5em 0;
  font-size: 0.4em;
  line-height: 1em;
  letter-spacing: -0.05em;
  border-top: 1px solid #1c1b1b;
  border-bottom: 1px solid #1c1b1b;
}

.wrap.hc .myInfoTop .company_CI {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
  width: 24.25em;
}
.wrap.hc .myInfoTop .company_CI .input_area img {
  margin: 0 auto;
  padding: 1em 2em;
  border: 1px solid #969696;
}
.wrap.hc .myInfoTop .company_CI .input_area input[type="file"] {
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
}
.wrap.hc .myInfoTop .company_CI .input_area label {
  display: block;
  margin: 1em auto 0.25em;
  padding: 0.5em 2em;
  font-size: 0.8em;
  color: #fff;
  text-align: center;
  background-color: #263050;
}
.wrap.hc .myInfoTop .company_CI .input_area p.size_guide {
  color: #646464;
  font-size: 0.65em;
  text-align: center;
}
.wrap.hc .myInfoBot .myPageList {
  background-color: #ededed;
}
.myInfoBot .myPageList.buy {
  margin: 1em 0 0;
  width: 100%;
}
.wrap.hc .myInfoBot .myPageList ul li a p.write_user {
  width: calc(100% - 5em);
}
.wrap.hc .myInfoBot .myPageList ul li a p.answer {
  position: absolute;
  right: 0;
  bottom: 1em;
}
.wrap.hc .myInfoBot .myPageList ul li a p.answer.ready {
  color: #e9360c;
}
.wrap.hc .myInfoBot .myPageList ul li a p.write_date {
  top: 0.125em;
  transform: none;
}
.myInfoBot .myPageList.event {
  margin-right: 0;
}

.wrap.hc .myContest {
  height: 23.5em;
  background-color: #ededed;
}

.search_area .search {
  width: calc(100% - 10em);
}
.search_area .filter {
  margin-left: 1em;
  width: 9em;
  border-bottom: 1px solid #565656;
}
.search_area .filter select {
  width: 100%;
  font-size: 0.75em;
  background: none;
  border: none;
}

/* 諛곕꼫�곹뭹 */
.prod_list ul li {
  position: relative;
  margin-bottom: 3em;
}
.prod_list.view ul li {
  margin-bottom: 1em;
}
.prod_list ul li > span {
  display: block;
  margin-bottom: 0.25em;
  font-weight: 600;
}
.prod_list ul li > span b {
  margin-right: 0.25em;
  padding: 0 0.25em;
  font-weight: 400;
  color: #fff;
  background-color: #272b35;
}
.prod_list ul li > a {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.25em 1em;
  font-size: 0.75em;
  color: #fff;
  background-color: #272b35;
}
.prod_list ul li > p {
  margin-bottom: 1em;
  font-size: 0.75em;
  letter-spacing: -0.05em;
}

.prod_list ul li .contest_detail.middle > ul > li {
  margin-bottom: 0;
}
.prod_list.view ul li .contest_detail.middle > ul > li {
  padding: 1em 0;
}
.prod_list.view ul li .contest_detail.middle > ul > li:first-child {
  padding-top: 2em;
}
.prod_list.view ul li .contest_detail.middle > ul > li:last-child {
  padding-bottom: 2em;
}
.prod_list ul li .contest_detail.middle > ul > li.character .cd_text ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.prod_list ul li .contest_detail.middle > ul > li.character .cd_text ul li {
  position: relative;
  margin: 0 3% 0.5em 0;
  padding-left: 1em;
  width: 35%;
}
.prod_list
  ul
  li
  .contest_detail.middle
  > ul
  > li.character
  .cd_text
  ul
  li:nth-child(2n) {
  margin-right: 0;
  width: 62%;
}
.prod_list
  ul
  li
  .contest_detail.middle
  > ul
  > li.character
  .cd_text
  ul
  li:before,
.prod_list ul li .contest_detail.middle > ul > li.cost .cd_text ul li:before {
  position: absolute;
  /* content: url(/src/assets/img/sub/prodList_chk.png); */
  top: 50%;
  left: 0;
  transform: translateY(-55%);
}
.prod_list ul li .contest_detail.middle > ul > li.cost .cd_text ul li {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1em;
  margin-bottom: 0.5em;
}
.prod_list ul li .contest_detail.middle > ul > li.cost .cd_text ul li span,
.prod_list ul li .contest_detail.middle > ul > li.cost .cd_text ul li p {
  margin: 0 0.5em 0 0;
  font-size: 1em;
}
.prod_list ul li .contest_detail.middle > ul > li.cost .cd_text ul li p {
  position: relative;
}
.prod_list ul li .contest_detail.middle > ul > li.cost .cd_text ul li p.origin {
  padding-right: 1em;
  text-decoration: line-through;
}
.prod_list
  ul
  li
  .contest_detail.middle
  > ul
  > li.cost
  .cd_text
  ul
  li
  p.origin:after {
  position: absolute;
  content: url(/src/assets/img/ico_next.png);
  top: 50%;
  right: 0;
  line-height: 0;
  transform: translateY(-50%);
}
.prod_list
  ul
  li
  .contest_detail.middle
  > ul
  > li.cost
  .cd_text
  ul
  li
  p.discount {
  font-weight: 600;
}
.prod_list ul li .contest_detail.middle > ul > li.size .cd_text {
  padding-top: 1em;
}
.prod_list ul li .contest_detail.middle > ul > li.size .cd_text span {
  font-size: 0.75em;
}

/* products buy */
.sub_container .contents.buy_complete .cost_complete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.sub_container .contents.buy_complete .cost_complete img {
  margin-bottom: 1em;
}
.sub_container .contents.buy_complete .cost_complete span {
  font-weight: 600;
}
.sub_container .contents.buy_complete .cost_info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 2em 0;
}
.sub_container .contents.buy_complete .cost_info ul {
  margin: 0 auto;
  width: 100%;
  max-width: 1020px;
  border-top: 2px solid #bababa;
}
.sub_container .contents.buy_complete .cost_info ul li {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #bababa;
}
.sub_container .contents.buy_complete .cost_info ul li span,
.sub_container .contents.buy_complete .cost_info ul li p {
  padding: 1em 0.5em;
}
.sub_container .contents.buy_complete .cost_info ul li span {
  font-size: 0.85em;
  background-color: rgba(0, 0, 0, 0.05);
}
.sub_container .contents.buy_complete .cost_info ul li p {
  font-size: 0.75em;
}

.sub_container .contents.buy_complete .cost_info span.guide {
  margin-top: 1em;
  font-size: 0.75em;
}

/* agent service */
.sub_container .slogan_area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3em;
}
.sub_container .slogan_area > span {
  font-size: 2em;
  text-align: center;
  letter-spacing: -0.05em;
}
.sub_container .slogan_area > span small {
  display: block;
  font-size: 0.75em;
}
.sub_container .slogan_area > p {
  margin-top: 0.5em;
  padding: 0 1em;
  letter-spacing: -0.04em;
  background-color: #fdd10c;
}

.sub_container img.agentImg {
  margin: 6em 0 3em;
}
.sub_container .btn_area.agent {
  padding-bottom: 2em;
  /* border-top: 1px solid #969696; */
}
.sub_container .btn_area.agent a.btn {
  background-color: #293253;
}

.sub_container .freeReg_title {
  margin-bottom: 2em;
  padding: 1.25em 0;
  border-top: 0.2em solid #fdd10c;
  border-bottom: 0.2em solid #fdd10c;
}
.sub_container .freeReg_title span {
  display: table;
  margin: 0 auto;
  font-size: 2em;
  font-weight: 600;
  letter-spacing: -0.05em;
}
.wrap.hc .sub_container .warning_list.weekly {
  margin: 2em auto;
  padding: 1em;
  width: 100%;
  max-width: 32.75em;
  border: 1px solid #dcdcdc;
}
.sub_container .warning_list li {
  position: relative;
  margin-bottom: 1em;
}
.sub_container .warning_list li span,
.sub_container .warning_list li p {
  position: relative;
  display: table;
}
.sub_container .warning_list li span {
  padding-left: 1em;
  font-weight: 500;
}
.sub_container .warning_list li span:before {
  position: absolute;
  content: "▶";
  top: 0.5em;
  left: 0;
  font-size: 0.6em;
}
.sub_container .warning_list li p {
  padding-left: 2em;
  font-size: 0.85em;
}
.sub_container .warning_list li p:before {
  position: absolute;
  content: "-";
  top: 0.25em;
  left: 1.5em;
  font-size: 0.8em;
}
.sub_container .warning_list li:last-child {
  margin-bottom: 0;
}

.sub_container .upgrade_service {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}
.sub_container .upgrade_service .text_area {
  width: calc(100% - 22.916em);
}
.sub_container .upgrade_service .text_area > span {
  font-size: 1.25em;
  letter-spacing: -0.05em;
  line-height: 1.2em;
}
.sub_container .upgrade_service .text_area .us_list {
  margin-top: 1em;
}

.sub_container .upgrade_service .text_area .us_list li {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5em;
}
.sub_container .upgrade_service .text_area .us_list li:last-child {
  margin-bottom: 0;
}
.sub_container .upgrade_service .text_area .us_list li span,
.sub_container .upgrade_service .text_area .us_list li p {
  position: relative;
  display: table;
  letter-spacing: -0.05em;
}
.sub_container .upgrade_service .text_area .us_list li span {
  padding-left: 0.75em;
  width: 100%;
  font-size: 1.05em;
  font-weight: 600;
}
.sub_container .upgrade_service .text_area .us_list li span:before {
  position: absolute;
  content: "▶";
  top: 50%;
  left: 0;
  font-size: 0.5em;
  transform: translateY(-50%);
}
.sub_container .upgrade_service .text_area .us_list li p {
  margin-left: 1em;
  padding-left: 0.75em;
  font-size: 0.9em;
}
.sub_container .upgrade_service .text_area .us_list li p:before {
  position: absolute;
  content: "●";
  top: 50%;
  left: 0;
  font-size: 0.5em;
  transform: translateY(-50%);
}
.sub_container .upgrade_service .text_area .btn_area {
  justify-content: flex-start;
  margin-top: 1em;
}
.sub_container .upgrade_service .text_area .btn_area a {
  margin: 0 1em 0 0;
  font-size: 0.8em;
  background-color: #263050;
  border-radius: 5em;
}
.sub_container .upgrade_service .text_area > span b {
  color: #263050;
}
.sub_container .upgrade_service .img_area {
  width: 22.916em;
}

.wrap.hc .sub_container .weekly_samp {
  margin: 0 auto;
  width: 100%;
  max-width: 32.75em;
}

.input_area.customdp .react-datepicker-wrapper {
  width: 30%;
}
.input_area.customdp .react-datepicker__input-container button {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.8em;
  padding: 0.3em 0.3em;
}
/* registration layout */
.sub_container .contents.mypage .regist_area {
  margin-bottom: 3em;
}
.regist_area > .sub_title {
  position: relative;
  display: block;
  color: #282c36;
  font-size: 1.5em;
  line-height: 1.8em;
  border-bottom: 1px solid #565656;
}
.regist_area > ul > li {
  align-items: flex-start;
  margin-bottom: 0;
  padding: 0.25em 0;
  border: none;
  background: none;
}
.regist_area > ul > li .input_title {
  font-weight: 600;
  line-height: 1.775em;
  position: relative;
}
.regist_area > ul > li .input_title.customRequire::before {
  content: "*";
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -1em;
  top: 4px;
}
.regist_area.products > ul > li .input_title {
  display: block;
  margin-bottom: 0.5em;
}
.regist_area .input_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.regist_area.products .input_area ul.typeSlt {
  width: 100%;
}
.regist_area .input_area label.input_title {
  padding-right: 1em;
}
.regist_area .input_area input,
.regist_area .input_area textarea {
  padding: 0.25em 0.5em;
  font-size: 0.85em;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.regist_area .input_area input.right {
  text-align: right;
}
.regist_area .input_area .slt_inCharge {
  padding: 0.175em;
  margin: 0 1.66666%;
  font-size: 0.85em;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.regist_area .input_area .slt_inCharge#contest_prizeRange,
.regist_area .input_area .slt_inCharge#event_category,
.form_area .input_area .slt_inCharge {
  margin: 0;
}
.regist_area .input_area .btn_overlap {
  margin-left: 1.6666%;
  padding: 0.4225em;
  text-align: center;
  font-size: 0.75em;
  color: #fff;
  background-color: #646464;
}
.regist_area .input_area span.dash {
  margin: 0 0.5em;
}
.regist_area .input_area .agreement {
  margin-top: 0.5em;
  width: 100%;
}
.regist_area .input_area .agreement.typeSlt {
  margin-top: 0;
}
.regist_area .input_area .agreement.typeSlt li {
  background-color: rgba(0, 0, 0, 0.05);
}
.regist_area .input_area .agreement li {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0.25em 0;
  width: 100%;
}
.regist_area .input_area .agreement li > span.agree_title {
  width: 11em;
}
.regist_area .input_area .agreement.typeSlt li > span.agree_title {
  width: 5em;
  text-align: center;
}
.regist_area .input_area .type_checkList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em 1em;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: calc(100% - 11em);
}
.regist_area.products .input_area .type_checkList {
  background: none;
  border: none;
}
.regist_area.products .input_area .type_checkList.input_area {
  padding: 0 0.5em;
  border: none;
}
.regist_area.products .input_area {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.regist_area.products .input_area p.cost {
  padding: 0 0.75em;
  font-size: 0.75em;
}
.regist_area .input_area .type_checkList {
  width: 100%;
}
.regist_area .input_area .agreement .type_checkList {
  padding: 0.5em 0 0.5em 1em;
  width: calc(100% - 5em);
}
.regist_area.products > ul > li {
  margin-bottom: 1em;
}
.regist_area.products > ul > li:first-child {
  margin-top: 1em;
}
.regist_area.products ul li ul li {
  margin-bottom: 0.25em;
}
.regist_area.products ul li ul li:last-child {
  margin-bottom: 0;
}
.regist_area.products ul li ul li .input_area span {
  padding: 0.5em 1em;
  font-size: 0.75em;
  background-color: rgba(0, 0, 0, 0.05);
}
.regist_area.products ul li ul li .input_area input[type="file"] {
  font-size: 0.75em;
  border: none;
}
.react-datepicer__input-container::before {
  content: "\e99c";
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 7px;
  font-size: 1rem;
  color: #333;
}
.react-datepicker__month-container .react-datepicker__header {
  background: white;
}
.regist_area.products ul li ul li .input_area p.imgGuide {
  padding: 0 1em;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.6em;
  text-align: right;
}

.regist_area .input_area label {
  margin-right: 1em;
  width: auto;
}
.regist_area .input_area .type_checkList label,
.regist_area .input_area.customChk label {
  margin: 0.25em 0.5em 0.25em 0;
  width: calc((100% - 2em) / 4);
  font-size: 0.85em;
}

.type_checkList.custom > label {
  text-align: center;
}
.type_checkList.custom > label.active {
  background-color: #2b303b;
  color: white;
}
.regist_area .input_area .type_checkList label.etc {
  width: calc((100% - 2em) / 3);
}

.regist_area.products .input_area .type_checkList label {
  margin: 0 1em 0 0;
  width: auto;
  font-size: 0.75em;
}
.regist_area .input_area.customChk label.register {
  width: auto;
}
.sub_container .btn_area {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sub_container .btn_area.board {
  justify-content: flex-end;
  padding: 1em 0;
  font-size: 0.75em;
}
.sub_container .btn_area.free_reg {
  margin: 3em 0;
  font-size: 0.75em;
}

.sub_container .btn_area.free_reg a {
  margin: 0 0.5em;
  border-radius: 0.5em;
  background-color: #263050;
}

.sub_container .btn_area.buy_complete a.btn {
  font-size: 0.75em;
}

.sub_container .btn_area a,
.sub_container .btn_area button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 1em;
  width: auto;
  color: #fff;
  font-size: 1.25em;
  border: none;
  cursor: pointer;
  background-color: #646464;
}
.sub_container .btn_area.regist button.regist {
  background-color: #263050;
}
.sub_container .btn_area a.btn {
  margin-left: 1em;
  padding: 0.5em 1em;
  width: auto;
  font-size: 1em;
}
.sub_container .btn_area.products {
  justify-content: flex-end;
}
.sub_container .btn_area.products a.btn {
  margin: 0 1em 0 0;
  font-size: 0.75em;
}
.sub_container .btn_area.products a.btn:last-child {
  margin-right: 0;
}
.sub_container .btn_area.products a.btn.buy {
  background-color: #272b35;
}

/* event registration */
.form_area {
  position: relative;
  padding: 0.5em 1em 1em;
  font-size: 0.75em;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.form_area > span.alert {
  display: block;
  margin-bottom: 0.5em;
}
.form_area > .btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.05em 0.4em;
  top: 0.4em;
  right: 0.75em;
  color: #fff;
  border: none;
  background-color: #646464;
}
.form_area > .btn span {
  font-size: 0.65em;
}
.form_area .form-group {
  margin-bottom: 1em;
  padding: 0 0.5em;
  border: 1px solid #969696;
}
.form_area .form-group:last-child {
  margin-bottom: 0;
}
.form-group > ul > li {
  margin-bottom: 0;
  padding: 0.5em 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: none;
}
.form-group > ul > li:last-child {
  border-bottom: none;
}
.form-group button.btn {
  margin-bottom: 0.5em;
  padding: 0.5em;
  display: block;
  width: 100%;
  color: #fff;
  font-size: 1em;
  background-color: #e35a1d;
  border: none;
}

/* loading pop */

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes pulse {
  50% {
    background: white;
  }
}
@keyframes pulse {
  50% {
    background: white;
  }
}

.loading_area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  z-index: 999;
}

.loading {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 0.25rem solid rgba(255, 255, 255, 0.2);
  border-top-color: white;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

.loading-pulse {
  position: relative;
  width: 15px;
  height: 15px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-animation: pulse 750ms infinite;
  animation: pulse 750ms infinite;
  -webkit-animation-delay: 250ms;
  animation-delay: 250ms;
  border-radius: 50%;
}
.loading-pulse:before,
.loading-pulse:after {
  content: "";
  position: absolute;
  display: block;
  height: 15px;
  width: 15px;
  background: rgba(255, 255, 255, 0.2);
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-animation: pulse 750ms infinite;
  animation: pulse 750ms infinite;
  border-radius: 50%;
}
.loading-pulse:before {
  left: -25px;
}
.loading-pulse:after {
  left: 25px;
  -webkit-animation-delay: 500ms;
  animation-delay: 500ms;
}

/* modal popup */

.section.customModify > p,
.section.customModify > ol {
  padding: 0.5em 0 0.5em 1em;
}

.section.customModify > ol > li {
  padding-bottom: 0.5em;
}
.section.customModify > ol > p {
  padding-bottom: 0.5em;
}
.section.customModify > ol > p.alert {
  font-weight: bold;
  padding-left: 1em;
  font-size: 0.8em;
  color: red;
  padding-bottom: 1em;
}
.section.customModify > ol > li > span.first_letter {
  font-weight: bold;
  padding-right: 1em;
}
.section.customModify > ol > li > p {
  padding: 0.5em 0;
}
.section.customModify > ol > li > p.alert {
  font-weight: bold;
  padding-left: 1em;
  font-size: 0.8em;
  color: red;
  padding-bottom: 1em;
}

.myInfo_event > ul > li a.modalButton {
  position: absolute;
  top: 1em;
  right: 1em;
  padding: 0.25em 1em;
  color: #fff;
  font-size: 0.75em;
  background-color: #263050;
  cursor: pointer;
  border-radius: 2em;
}

.modalArea {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
section.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
}
section.modalWindow {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999 !important;
  width: 80vw;
  min-height: 200px;
  max-height: 90vh;
  background: #fff;
  cursor: default;
  transform: translate(-50%, -50%);
}
section.modalWindow.imgDetail {
  width: auto;
}

section.modal.overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 997;
  cursor: pointer;
}
section.modalWindow h2 {
  padding: 1em 1em 0.5em;
  font-size: 1em;
  border-bottom: 1px solid #969696;
}
section.modalWindow section.modalWrapper {
  padding: 1em;
  max-height: 70vh;
  overflow-y: auto;
}
section.modalWindow section.modalWrapper ul.table {
  border-top: 1px solid #969696;
}
section.modalWindow section.modalWrapper ul.table > li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1em 0;
  font-weight: 400;
}
section.modalWindow section.modalWrapper ul.table > li.th {
  padding: 0.4em 0;
  border-bottom: 1px solid #969696;
}
section.modalWindow section.modalWrapper ul.table > li.th span {
  font-size: 0.85em;
  text-align: center;
}
section.modalWindow section.modalWrapper ul.table > li.td {
  font-size: 0.7em;
  border-bottom: 1px solid rgb(200, 200, 200);
}
section.modalWindow section.modalWrapper ul.table > li.td:last-child {
  border-color: #969696;
}
section.modalWindow section.modalWrapper ul.table > li.td .regContent .userInfo,
section.modalWindow
  section.modalWrapper
  ul.table
  > li.td
  .regContent
  .regInfo
  > ul
  > li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
section.modalWindow
  section.modalWrapper
  ul.table
  > li.td
  .regContent
  .userInfo {
  margin-bottom: 0.75em;
}
section.modalWindow
  section.modalWrapper
  ul.table
  > li.td
  .regContent
  .userInfo
  span {
  margin-right: 1em;
  padding-right: 1em;
  color: #646464;
  line-height: 1em;
  border-right: 1px solid rgb(200, 200, 200);
}
section.modalWindow
  section.modalWrapper
  ul.table
  > li.td
  .regContent
  .userInfo
  span:first-child {
  color: #323232;
  font-weight: 600;
}
section.modalWindow
  section.modalWrapper
  ul.table
  > li.td
  .regContent
  .regInfo
  > ul
  > li {
  margin-bottom: 0.25em;
}
section.modalWindow
  section.modalWrapper
  ul.table
  > li.td
  .regContent
  .regInfo
  > ul
  > li:last-child {
  margin-bottom: 0;
}
section.modalWindow
  section.modalWrapper
  ul.table
  > li.td
  .regContent
  .regInfo
  > ul
  > li
  span {
  display: block;
}
section.modalWindow
  section.modalWrapper
  ul.table
  > li.td
  .regContent
  .regInfo
  > ul
  > li
  span.regTit {
  margin-right: 0.5em;
  width: 10em;
  text-align: center;
  border: 1px solid rgb(200, 200, 200);
}
section.modalWindow
  section.modalWrapper
  ul.table
  > li.td
  .regContent
  .userInfo
  span:last-child {
  margin-right: 0;
  padding-right: 0;
  border: none;
}
section.modalWindow section.modalWrapper ul.table > li.td .regNum,
section.modalWindow section.modalWrapper ul.table > li.td .regPeriod {
  text-align: center;
}
section.modalWindow section.modalWrapper ul.table > li.td .regPeriod > span {
  display: block;
}
section.modalWindow .btn_area {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  border-top: 1px solid #969696;
}
section.modalWindow .btn_area a {
  margin: 0 1em;
  padding: 0.5em 1em;
  color: #fff;
  font-size: 0.75em;
  background-color: #29407c;
}
.closeBtn {
  position: absolute;
  right: 0;
  top: -1.25em;
  cursor: pointer;
}

/* 20.05.11 寃뚯떆�� �곸꽭�섏씠吏� 泥⑤��뚯씪 異붽�*/
.board_view ul li.td.file .input_area span.td_title {
  margin-right: 1em;
  font-weight: 600;
}

/* 20.05.14 �ъ씠�몃㏊ 異붽� */
.section.sitemap {
  margin: 0 auto;
  width: 1188px;
}

.section.sitemap > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.section.sitemap > ul > li {
  margin-right: 1em;
  margin-bottom: 1em;
  width: calc((100% - 3em) / 4);
}
.section.sitemap > ul > li:nth-child(4n) {
  margin-right: 0;
}
.section.sitemap > ul > li h3 {
  margin-bottom: 0.5em;
  padding: 0.25em;
  color: #fff;
  text-align: center;
  background-color: #282c36;
}
.section.sitemap > ul > li a {
  display: block;
  padding: 0.25em 0;
  font-size: 0.75em;
  border-bottom: 1px solid #bbb;
}

/* quill editor css  */
.ql-align-justify {
}
.ql-align-center {
  text-align: center;
}
.ql-align-center img {
  margin: auto;
}
.ql-align-right {
  text-align: right;
}
.ql-indent-1 {
  padding-left: 3em !important;
}
.ql-indent-2 {
  padding-left: 6em !important;
}
.ql-indent-3 {
  padding-left: 9em !important;
}
.ql-indent-4 {
  padding-left: 12em !important;
}
