.wrap {
  position: relative;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  .inputWrap {
    @media screen and (max-width: 755px) {
      font-size: 0.75em;
    }
    .labelArea {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.25em;
      font-weight: 600;
    }

    .inputArea {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin-top: 0.5em;
      margin-bottom: 0.5em;

      > .text {
        @media screen and (max-width: 755px) {
          width: 80%;
          height: auto;
        }
        width: 90%;
        margin-right: 1em;
        height: 120px;
        border-color: #dbdbdb;
        border-radius: 10px;
        padding: 0.5em;
        font-size: 0.8em;
        outline-color: #22253069;
      }
      > button {
        @media screen and (max-width: 755px) {
          width: 20%;
          font-size: 0.75em;
        }
        width: 10%;
        border: 1px solid #dbdbdb;
        border-radius: 10px;
        cursor: pointer;
        &.submit {
          background-color: #3f4453;
          color: white;
        }
      }
    }
  }

  .listWrap {
    margin-top: 1em;

    .countArea {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //   border-bottom: 1px solid #dbdbdb;

      > label {
        font-size: 0.8em;
        color: #a8a8a8;
      }
      padding-bottom: 0.5em;
    }

    .itemArea {
      border-top: unset;
      background-color: unset;
      .item {
        margin-bottom: 2em;
        // background-color: #ffffff82;

        padding: 0.5em 1em;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 1em;
        // border-radius: 0.5em;
        // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        .nameArea {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > label {
            font-size: 0.8em;
            color: #3f4453;
            font-weight: 600;

            > span {
              margin-left: 0.2em;
            }
          }

          .btnArea {
            font-size: 0.7em;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .btn {
              padding: 0.2em 0;
              margin-left: 0.5em;
              margin-right: 0.5em;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #c8c8c8;
              cursor: pointer;

              > span:first-child {
                margin-right: 0.3em;
              }
            }
          }
        }

        .textArea {
          padding-top: 0.5em;
          > p {
            font-size: 0.75em;
            color: #808080;

            .annotation {
              margin-right: 1em;
            }
            &.date {
              text-align: right;
              font-size: 0.55em;
              color: #c8c8c8;
            }
          }
        }

        .subReply {
          padding-top: 0.5em;
          display: block;

          .subStatus {
            font-size: 0.65em;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &.nomore {
              justify-content: flex-end;
            }

            .subCount {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              cursor: pointer;
            }
            .addSubReply {
              cursor: pointer;
            }

            .subInputWrap {
              width: 100%;
              display: block;
              .btnArea {
                text-align: right;
                margin-bottom: 0.3em;
                padding-right: 5px;
                > span {
                  color: gray;
                  font-size: 1.2em;
                }
              }
              .writeArea {
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                .subInput {
                  width: 90%;
                  margin-right: 1em;
                  height: 80px;
                  border-color: #dbdbdb;
                  border-radius: 10px;
                  padding: 0.5em;
                  font-size: 16px;
                  // font-size: 0.95em;
                  outline-color: #22253069;
                }
                .subInputBtn {
                  width: 10%;
                  border: 1px solid #dbdbdb;
                  border-radius: 10px;
                  cursor: pointer;
                  &.submit {
                    background-color: #3f4453;
                    color: white;
                  }
                }
              }
            }
          }

          .subItem {
            // background-color: rgba(0, 0, 0, 0.01);
            padding: 0.8em 0em 0.8em 0.7em;
            margin-left: 0.5em;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}

.otherBtn {
  font-size: 0.75em;
  text-align: right;
  margin-top: 5px;

  > ul {
    border-top: none !important;
    background-color: unset !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    > li {
      cursor: pointer;
      margin-left: 0.8em;
      padding: 0.2em 0.5em;
      border-radius: 10px;
      background-color: rgb(63, 68, 83);
      color: white;
    }
  }
}
