.band {
  cursor: pointer;

  .a {
    cursor: pointer;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-left: 10px;
    padding-right: 10px;
  }
}
