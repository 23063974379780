@import "./media.scss";
@import "./scroll.module.scss";
@import "./common.module.scss";
$b_1440_max_width: 950px;

$b_1024_max_width: 650px;
$b_1024_max_width_csWrap: 800px;

$b_768_max_width: 500px;

.indicateCategory {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.3em 0;
  flex-wrap: wrap;
  font-size: 0.65em;

  > li {
    padding: 0.5em 0.3em;
    margin-right: 0.5em;
    color: gray;

    &.checked {
      background: #4285f4;
      border-radius: 5em;
      color: white;
    }
  }
}

.subVisual {
  @include bp768 {
    height: 8.5em;
  }
  @include bp425 {
    height: 8.5em;
  }
  > ul.sub_menu {
    @include hide-scroll-bar;
    @include bp768 {
      flex-wrap: nowrap;
      overflow-x: scroll;
      align-items: unset;
      justify-content: unset;

      > li {
        &:nth-child(4n) {
          margin-right: 0.5em;
        }
        width: auto;
        > a {
          width: 200px;
        }
      }
    }
    @include bp425 {
      flex-wrap: nowrap;
      overflow-x: scroll;
      align-items: unset;
      justify-content: unset;

      > li {
        &:nth-child(4n) {
          margin-right: 0.5em;
        }
        width: auto;
        > a {
          width: 200px;
        }
      }
    }
  }
}

.sub_container {
  //공모전 상세
  .contents {
    @include bp425 {
      padding-top: 1em;
    }
    .contest_view {
      .contest_detail {
        &.top {
          @include bp768 {
            flex-direction: row-reverse;
          }
          @include bp425 {
            flex-direction: column;
          }

          // 공모전 상세 썸네일 영역
          > .contest_thumb {
            margin-right: 2em;
            @include bp768 {
              margin-right: 0;
            }
            @include bp425 {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 0;
              padding-bottom: 1em;
            }
          }

          // 공모전 상세 텍스트 영역
          > .contestTop_text {
            @include bp768 {
              margin-right: 1em;
            }
            @include bp425 {
              width: auto;
            }
            .contest_btn {
              overflow: scroll;
              -ms-overflow-style: none;
              scrollbar-width: none;
              &::-webkit-scrollbar {
                display: none;
              }
              @include bp1024 {
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
              }
              @include bp768 {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
              }
              @include bp425 {
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
              }
              > a {
                margin-left: 0.25em;
                margin-bottom: 0.25em;
                @include bp1024 {
                  white-space: nowrap;
                }
                @include bp768 {
                  margin-left: 0;
                  white-space: nowrap;
                }
                @include bp425 {
                  white-space: nowrap;
                }
              }
            }

            .ct_bottom {
              > ul {
                &.ctTop_list {
                  @include bp768 {
                    flex-direction: column;
                  }
                  @include bp425 {
                    flex-direction: column;
                  }

                  > li {
                    @include bp768 {
                      width: 100%;
                    }
                    @include bp425 {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      // 공모전 모집 요강 내용
      .contest_detail {
        &.middle {
          > ul {
            > li {
              @include bp768 {
                &::before {
                  left: 1em;
                }
              }
              @include bp425 {
                flex-direction: column;
                align-items: flex-start;
                &::before {
                  display: none;
                  left: 1em;
                }
              }

              > div.cd_title {
                @include bp768 {
                  width: auto;
                  margin-left: 2em;
                }
                @include bp425 {
                  width: auto;
                }

                > span {
                  @include bp768 {
                    &::after {
                      left: -0.7em;
                    }
                  }
                  @include bp425 {
                    &::after {
                      display: none;
                      left: 0.5em;
                    }
                  }
                }
              }
              > div.cd_text {
                @include bp768 {
                  width: auto;
                }
                @include bp425 {
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
  }

  // 조건 검색 영역
  .custom_search {
    &.hide {
      height: fit-content;
      transition: height 0.5s ease;
      overflow: hidden;
    }

    > .cs_wrap {
      .customFilter {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        border-top: 1px solid rgba(0, 0, 0, 0.2);
        .filterResult {
        }
        .filterCg {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 1em;

          .cgGroup {
            font-size: 1.2rem;
            padding: 10px;
            padding-bottom: 5px;
            margin-right: 10px;

            &.active {
              font-weight: 600;
              border-bottom: 2px solid red;
            }
          }
        }

        .filterItems {
          display: none;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;

          &.active {
            display: flex;
          }
          .filterItem {
            font-size: 0.9rem;
            padding: 5px 30px;
            margin-right: 20px;
            margin-bottom: 10px;
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 20px;

            &.active {
              background-color: rgba(0, 0, 0, 0.5);
              color: "white";
            }
          }
        }
      }
      .cs_filter {
        padding-bottom: 4em;
        @include bp768 {
          padding-bottom: unset;
        }
        @include bp425 {
          padding-bottom: unset;
        }

        .selectFilter {
          @include bp768 {
            display: none;
          }
          @include bp425 {
            display: none;
          }
        }
        li {
          span {
            width: auto;
            padding: 0.5em 0.7em;
            color: #bcbcbc;
          }
          &:first-child {
            span {
              padding-left: 0;
            }
          }
          &.active {
            color: black;
            font-weight: 500;
          }
        }
      }
      @include bp1440 {
        max-width: $b_1440_max_width;
      }
      @include bp1024 {
        max-width: $b_1024_max_width_csWrap;
      }
      @include bp768 {
        .cs_filter {
          > li {
            > div.input_area {
              flex-wrap: nowrap;
              overflow-x: scroll;
              width: 100%;
              > label {
                width: 100%;
                margin: 0 1em;
              }
            }
          }
        }
      }
      @include bp425 {
        .cs_filter {
          > li {
            > div.input_area {
              flex-wrap: nowrap;
              overflow-x: scroll;
              width: 100%;
              @include hide-scroll-bar;
              > label {
                width: 100%;
                margin: 0 1em;
              }
            }
          }
        }
      }
      // @include bp768 {
      // 	max-width: $b_768_max_width;
      // }
    }
  }
  .contest_area {
    &.hidden {
      height: 0;
      transition: height 0.5s ease;
      overflow: hidden;
      padding: 0;
    }
    .board_search {
      > input {
        @include common-input-border;
      }
      > select {
        @include common-input-border;
      }
    }
    .filter_area {
      > label {
        color: white;
      }
    }
    .contest {
      > ul {
        > li {
          border: 1px solid #cfcfcf;
        }
      }
    }
    @include bp1440 {
      max-width: $b_1440_max_width;
    }
    @include bp1024 {
      max-width: $b_1024_max_width;
    }
    @include bp768 {
      .contest {
        > ul {
          > li {
            &.recomm {
              &:before {
                left: unset;
                right: 0;
                border-radius: 0;
              }
            }
            div.contestList_info {
              flex-direction: column;
              align-items: unset;
              width: 50%;

              > span {
                margin-bottom: 1em;
                border: none;
              }
            }
            div.contestList_date {
              flex-direction: column;
              align-items: end;
              > span.contest_dDay {
                margin-top: 1em;
              }
            }
          }
        }
      }
    }
    @include bp425 {
      .contest {
        > ul {
          > li {
            flex-direction: column;
            padding: 1.25em 1em;
            &.recomm {
              &:before {
                left: unset;
                right: 0;
                border-radius: 0;
              }
            }
            div.contestList_info {
              width: 100%;
              margin-right: 0;
              flex-direction: column;
              align-items: unset;

              > span {
                margin-bottom: 1em;
                border: none;
              }
            }
            div.contestList_date {
              width: 100%;
              justify-content: space-between;
              > span.contest_dDay {
              }
            }
          }
        }
      }
    }
  }
}
