@import "./media.scss";
$bp-1024-max-width: 900px;
$bp-1024-max-img: 500px;

$bp-768-max-width: 650px;
$bp-768-max-img: 370px;

$bp-425-max-width: 350px;
$bp-425-max-img: 200px;

.mediaHeader {
  > .snb_wrap {
    position: fixed;
    right: 0;
    top: 20%;
    background-color: rgba(0, 0, 0, 0.2);
    &.indicator {
      &.hide {
        right: 0;
        transform: translateX(0);
        transition-property: all;
        transition-duration: 0.5s;
      }
      &.open {
        right: -1em;
        transform: translateX(100px);
        transition-property: all;
        transition-duration: 0.5s;
      }
    }
    &.hide {
      right: -1em;
      transform: translateX(100px);
      transition-property: all;
      transition-duration: 0.5s;
    }
    &.open {
      right: 0;
      transform: translateX(0);
      transition-property: all;
      transition-duration: 0.5s;
    }
  }
  > #header_wrap {
    .gnb {
      &.active {
        height: 2000px;
        background-color: rgba(0, 0, 0, 0.9);
      }
      > nav {
        width: 100%;
        > .nav_category {
          width: 100%;
          @include bp768 {
            top: 0;
            left: 5%;
            transform: none;
            > ul {
              padding-top: 3.5em;
              display: block;
              > li {
                > ul {
                  &.submenu {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                  }
                }
              }
            }
          }
          @include bp425 {
            top: 0;
            left: 5%;
            transform: none;
            > ul {
              padding-top: 3.5em;
              display: block;
              > li {
                > ul {
                  &.submenu {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: center;
                  }
                }
              }
            }
          }
          @include bp320 {
            top: 0;
            left: 5%;
            transform: none;
            > ul {
              padding-top: 3.5em;
              display: block;
              > li {
                > ul {
                  &.submenu {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: center;
                  }
                }
              }
            }
          }
        }
      }
    }
    @include bp1024 {
      max-width: $bp-1024-max-width;
      .ab_right {
        margin-top: 0.2em;
        justify-content: space-between;
        width: 10%;
      }
    }
    @include bp768 {
      max-width: $bp-768-max-width;
      padding: 0 0.5em;

      .main_nav {
        display: none;
      }
      .ab_right {
        margin-top: 0.2em;
        justify-content: space-between;
      }
    }
    @include bp425 {
      max-width: $bp-425-max-width;
      justify-content: flex-start;
      .main_nav {
        display: none;
      }
      .ab_right {
        margin-top: 0.2em;
        justify-content: space-between;
        width: 40%;
      }
    }
  }
}
@keyframes blinkText {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.mediaContainer {
  > .section1 {
    .detailList {
      .info_area {
        span {
          &.info_title {
            &.custom_loading {
              color: rgba(255, 255, 255, 0.1);
              width: 300px;
              height: 40px;
              background-color: rgba(255, 255, 255, 0.1);
              text-shadow: none;
              border-radius: 5px;
              padding: 10px 2.5px;
              animation: blinkText 1s ease-in-out infinite;
            }
          }
          &.custom_loading_date {
            height: 25px;
            width: 500px;
            background-color: rgba(255, 255, 255, 0.1);
            padding: 10px 2.5px;
            border-radius: 5px;
            animation: blinkText 1s ease-in-out infinite;
          }
          &.custom_loading_etc {
            width: 150px;
            height: 25px;
            padding: 10px 2.5px;
            border-radius: 5px;
            background-color: rgba(255, 255, 255, 0.1);
            animation: blinkText 1s ease-in-out infinite;
            margin-bottom: 1px;
          }
        }
        @include bp1024 {
          max-width: $bp-1024-max-width;
        }
        @include bp768 {
          max-width: $bp-768-max-width;
          span {
            &.info_title {
              color: transparent;
              width: 200px;
            }
          }
        }
        @include bp425 {
          max-width: $bp-1024-max-width;
          padding: 1em 0.25em;
          background-color: rgba(255, 255, 255, 0.8);
          span {
            color: black;
            &.info_title {
              width: 100%;
              overflow-x: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            &.info_Dday {
              > b {
                border: 1px solid black;
              }
            }
          }
          a {
            color: black;
            border: 1px solid black;
          }
        }
      }
    }
    .listArea {
      @include bp1024 {
        max-width: $bp-1024-max-width;
      }
      @include bp768 {
        max-width: $bp-768-max-width;
      }
      @include bp425 {
        max-width: $bp-1024-max-width;
        bottom: -3em;
        .thumbList {
          .info_area {
            .info_title {
              @include bp425 {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  > .section2 {
    .detailList {
      .info_area {
        @include bp1024 {
          width: calc(100% - $bp-1024-max-img);
        }
        @include bp768 {
          width: calc(100%);
          position: absolute;
          display: block;
          &.spa {
            position: relative;
          }
          > a {
            position: unset;
          }
        }
        @include bp425 {
          width: calc(100%);
          position: absolute;
          display: block;
          &.spa {
            position: relative;
          }
          > a {
            position: unset;
          }
        }
      }
      @include bp1024 {
        img {
          &.detailImg {
            width: $bp-1024-max-img;
          }
        }
      }
      @include bp768 {
        img {
          &.detailImg {
            width: 100%;
          }
        }
      }
      @include bp425 {
        img {
          &.detailImg {
            width: 100%;
          }
        }
      }
    }
    .listArea {
      @include bp1024 {
        max-width: $bp-1024-max-width;
      }
      @include bp768 {
        max-width: $bp-768-max-width;
      }
    }
  }

  > .section4 {
    .sectionTit {
      @include bp1024 {
        max-width: $bp-1024-max-width;
      }
      @include bp768 {
        max-width: $bp-768-max-width;
      }
      @include bp425 {
        > a {
          &.mt_btn {
            display: none;
          }
        }
      }
    }
    .banner_list {
      @include bp1024 {
        max-width: $bp-1024-max-width;
      }
      @include bp768 {
        max-width: $bp-768-max-width;
      }
    }
  }

  > .section6 {
    // .client {
    // 	@include bp768 {
    // 		max-width: $bp-768-max-width;
    // 	}
    // }
  }
}

.footer {
  footer {
    @include bp1024 {
      max-width: $bp-1024-max-width;
      .csArea {
        width: 100% !important;
      }
    }
    @include bp768 {
      max-width: $bp-768-max-width;
      display: block;
      dd {
        margin: 1em 0;
      }

      .csArea {
        width: 100% !important;
      }
    }
    @include bp425 {
      max-width: $bp-768-max-width;
      display: block;
      dd {
        margin: 1em 0;
      }
      .csArea {
        width: 100% !important;
        ul {
          font-size: 0.5em;
        }
      }
    }
  }

  #footerLink {
    @include bp1024 {
      max-width: $bp-1024-max-width;
    }
    @include bp768 {
      max-width: $bp-768-max-width;
    }
    @include bp425 {
      max-width: $bp-768-max-width;
      flex-wrap: wrap;
    }
  }
}

.floating_area {
  @include bp1024 {
    padding: 0 1em;
  }
  @include bp768 {
    padding: 0.5em 1em 0 1em;
    > .floating_cont {
      flex-direction: column;
    }
  }
  @include bp425 {
    padding: 0.5em 0.5em 0.5em 0.5em;
    > .floating_cont {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      > .text_area {
        margin-bottom: 0.5em;
      }
    }
  }
}
