@import "./media.scss";

$bp_1024_m_width: 756px;

.subVisual {
	&.agent {
		@include bp425 {
			> span {
				font-size: 1.5em !important;
				small {
					margin-top: 0.5em;
				}
			}
		}
	}
	> span {
		@include bp425 {
			font-size: 2em !important;
		}
	}
}

.sub_container {
	> .btn_area {
		&.free_reg {
			@include bp425 {
				> a {
					font-size: 1em;
				}
			}
		}
	}

	> .contents {
		&.products {
			@include bp1024 {
				max-width: $bp_1024_m_width;
			}
		}
		&.mypage {
			@include bp425 {
				padding-top: 0em;
			}
			.regist_area {
				&.contest {
					&.manager {
						@include bp425 {
							margin-bottom: 1em;
						}
					}
					> ul {
						> li {
							@include bp425 {
								flex-direction: column;
								> label.input_title {
									width: 100% !important;
								}
							}

							> div {
								@include bp425 {
									width: 100% !important;
									> ul {
										> li {
											> span {
												font-weight: 600;
											}
											flex-direction: column;
											align-items: flex-start;
										}
									}

									&.input_area {
										&.agentType {
											flex-direction: column;
											align-items: flex-start;
										}

										.select {
											width: 100% !important;
										}
									}
								}
							}
							> textarea {
								@include bp425 {
									&.textarea {
										width: 100% !important;
										border: 1px solid rgba(0, 0, 0, 0.1);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
