@import "./media.scss";

.dataList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  @include bp768 {
    justify-content: center;
    align-items: center;
  }
  @include bp425 {
    justify-content: center;
    align-items: center;
  }

  .newConAndAct {
    width: 280px;
    padding: 0 !important;
    margin: 1em 0.3em;
    border: none !important;
    background: #534c6e;

    > a {
      // height: 400px;

      img.mainThumb {
        width: 280px;
        height: 400px;
        @include bp768 {
          opacity: 0;
          visibility: hidden;
        }
        @include bp425 {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .hover {
      // transition: all 0.5s fadein;
      position: absolute;
      width: 280px;
      height: 400px;
      left: 0;
      top: 0;

      font-size: 0.75em;
      visibility: hidden;
      padding: 1em;
      opacity: 0;
      transition: opacity 500ms, visibility 500ms;
      @include bp768 {
        // position: relative;
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.9);
        visibility: visible;
        transition: unset;
      }
      @include bp425 {
        // position: relative;
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.9);
        visibility: visible;
        transition: unset;
      }
    }
    .open {
      position: absolute;
      width: 280px;
      height: 400px;
      top: 0;
      left: 0;
      font-size: 0.75em;
      padding: 1em;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.9);
      transition: opacity 500ms;
    }

    .blockContent {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;

      span {
        color: #e9e9e9;
      }

      > p {
        width: 280px;
        display: block;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.title {
          font-size: 0.85em;
          color: #e9e9e9;
        }
        &.hostName {
          font-size: 0.6em;
          color: #bcbcbc;
        }
      }

      .status {
        margin-top: 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 0.6em;

          &.statusBox {
            padding: 0.2em 0.7em;
            border-radius: 1em;

            &.ready {
              background-color: #ff3c00;
            }
            &.onAir {
              background-color: #0dbf76;
            }
            &.closeAir {
              background-color: #bcbcbc;
            }
          }

          &.endDate {
            color: #e9e9e9;
          }
        }
      }
      .viewCnt {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 0.65em;

        > span {
          margin-left: 0.5em;
          color: #bcbcbc;
        }
      }
    }

    .hoverContent {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;

      .titleAndHost {
        span.title {
          width: 260px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: white;
        }
        p.hostName {
          width: 260px;
          color: #bcbcbc;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .minThumb {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .dateAndViewCnt {
        display: flex;
        flex-direction: column;
        .etcInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span.date {
            color: black;
            background: white;
            padding: 0.1em 0.4em;
            border-radius: 0.2em;
            &.onAir {
              background: #0dbf76;
              color: white;
            }
            &.done {
              background: whitesmoke;
              color: black;
            }
            &.ready {
              background: #ff3c00;
              color: white;
            }
          }

          .viewCnt {
            // margin-bottom: 0.5em;
            color: white;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .cnt {
              margin-left: 0.3em;
            }
          }
        }
        p.deadLine {
          margin-top: 0.2em;
          text-align: right;
          color: #bcbcbc;
          font-size: 0.85em;
        }
      }
    }
  }
}
