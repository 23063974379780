@mixin common-input-border {
	border: 1px solid #dcdcdc;
}
@mixin flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
@mixin common-border {
	border: 1px solid #dcdcdc;
}
@mixin common-border-right {
	border-right: 1px solid #dcdcdc;
}
