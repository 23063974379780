// breakpoint
$bp-1440: 1440px;
$bp-1200: 1200px;
$bp-1024: 1024px;
$bp-910: 910px;
$bp-768: 768px;
$bp-425: 425px;
$bp-320: 320px;

@mixin bp320 {
  @media (max-width: $bp-320) {
    @content;
  }
}

@mixin bp425 {
  @media (min-width:#{$bp-320}) and (max-width:#{$bp-425}) {
    @content;
  }
}

@mixin bp768 {
  @media (min-width:#{$bp-425 + 1px}) and (max-width:#{$bp-768}) {
    @content;
  }
}

@mixin bp1024 {
  @media (min-width: #{$bp-768 + 1px}) and (max-width:#{$bp-1024}) {
    @content;
  }
}
@mixin bp1200 {
  @media (min-width: #{$bp-1024 + 1px}) and (max-width:#{$bp-1200}) {
    @content;
  }
}
@mixin bp1440 {
  @media (min-width: #{$bp-1024 + 1px}) and (max-width:#{$bp-1440}) {
    @content;
  }
}
